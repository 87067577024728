import React, { Component, useRef, useState, useContext } from "react";

import { Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import HoverControlledSplitButton from "./ProductsList";
import RefreshBearerToken from "./RefreshBearerToken";
import StartAddedToCartTimer from "./AddedToCartTimer";
import NavDropdown from "react-bootstrap/NavDropdown";
import Swal from "sweetalert2";
import { FaQuestion } from "react-icons/fa";

import {
  ControlledMenu,
  useHover,
  useMenuState,
  Menu,
  MenuItem,
  MenuButton,
  SubMenu,
} from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import "@szhsin/react-menu/dist/transitions/slide.css";
import {
  BsFillHeartFill,
  BsFillHouseDoorFill,
  BsHouse,
  BsPerson,
  BsCart2,
  BsHeart,
  BsCash,
  BsBag,
} from "react-icons/bs";
import { TbCoinRupee } from "react-icons/tb";

import WishListView from "./WishListView";
import { LoadSpinner } from "./LoadSpinner";
import UserLoginNew from "./UserLoginNew";

import { useNavigate } from "react-router-dom";
import {
  Badge,
  OverlayTrigger,
  Popover,
  Stack,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import CartContext from "./CartContext";
import SearchView from "./SearchView";
import Select from "react-select";
import { getMainCategories } from "../Helper/ConsumerHelper";
import "../CSS/textanimation.css";
import { Navbar, Nav, Button, Form, FormControl } from "react-bootstrap";
import { render } from "@testing-library/react";
import CartOffCanvas from "./CartOffCanvas";
import { LanguageSelection } from "./LanguageSelection";

export default class Navigation extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loginRefreshCounter: 1,
      storeId: "",
      loadSpinnerOpen: false,
      firstload: false,

      // ShowUserLoginNew: false,
    };
    this.unload.bind(this);
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  refreshBearerToken = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.RefreshBearerToken(
      this.context.userData.emailId,
      this.context.userData.password,
      this.context.userData
    );
  };
  refreshBearerTokenWithMobileNo = (newval) => {
    this.setState({ loginRefreshCounter: newval + 1 });
    console.log("refresh called");
    this.context.refreshBearerTokenWithMobileNo(
      this.context.userData.mobileNo,
      this.context.userData
    );
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          if (new Date(data.storeEnd) > new Date() == false) {
            alert("Store is Inactive. Please contact your Vendor!");
            return;
          }
          this.context.setStoreId(data.id);
          this.context.SetStore(data);
          if (this.state.storeId != "") return;
          this.setState({ storeId: data.id }, () => {
            this.context.GetStoreSettings();

            this.context.GetMultiCurrencys();
            this.context.GetDisplayCounter();
            this.context.GetVisitorsCount();
            this.context.GetMainCarousel(1); //1-desktop 2-Mobile
            this.context.GetOrderStatusTypes();
            this.context.getMainCategories();
            this.context.getSubCategories();
            this.context.getBrands();
            this.context.GetMainMenus();
            this.context.GetTop5Ads();
            this.context.GetAllProducts();
            this.context.GetProductGroups();
            this.context.getShippingDetails();
            this.context.getTaxDetails();
            this.context.GetProductDataType();
            this.context.StoreMyIpAddress();
            this.context.GetQuoteStatusTypes();
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("GetMyStoreDetails fetch err:" + error);
      });
  };

  fakeRequest = () => {
    // return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    return new Promise((resolve) => setTimeout(() => resolve(), 100));
  };
  componentDidMount() {
    window.addEventListener("beforeunload", this.unload);

    const platform = window.navigator.platform;
    if (
      platform.includes("Mac") ||
      platform.includes("Sun") ||
      platform.includes("iPhone")
    ) {
      this.fakeRequest().then(() => {
        if (this.state.storeId == "") {
          this.GetMyStoreDetails();
        }
      });
    } else {
      if (this.state.storeId == "") {
        this.GetMyStoreDetails();
      }
    }
  }
  componentWillUnmount() {
    window.removeEventListener("beforeunload", this.unload);
  }
  unload(e) {}

  componentDidUpdate() {
    if (this.context.storeSettings.favicon) {
      document.getElementsByTagName("Link")[0].href =
        this.context.store.storageBlobUrl +
        this.context.store.storageBlobContainerName +
        "/images/" +
        this.context.storeSettings.favicon +
        this.context.store.storageSasToken;
    } else {
      document.getElementsByTagName("Link")[0].href =
        window.location.href + "favicon.ico";
    }

    var countrycode = localStorage.getItem("CountryCode");
    if (
      countrycode &&
      this.context.islanguageSelected == false &&
      this.context.storeSettings.multiCurrencySupport == true &&
      this.context.multiCurrenciesSetting.length > 0 &&
      this.context.prodsAll.length > 0
    ) {
      this.context.SetSelectedCountryCode(countrycode, this.context.prodsAll);
      this.context.SetIslanguageSelected(true);
    }
  }

  HideUserLoginNew = () => {
    //this.setState({ ShowUserLoginNew: false });
    this.context.ShowUserLoginNew(false);
  };

  SignOut = () => {
    //this.setState({ ShowUserLoginNew: true });
  };

  render() {
    if (this.context.userData.loginStatus == "active") {
      if (this.context.prevPage != "") {
        var prevPage = this.context.prevPage;
        this.context.storePreviousPage("");
        return <Navigate to={prevPage} />;
      }
    }

    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        {this.context.AddedToCartMsg != "" ? (
          <StartAddedToCartTimer></StartAddedToCartTimer>
        ) : (
          ""
        )}

        {this.context.userData.loginStatus === "active" ? (
          <RefreshBearerToken></RefreshBearerToken>
        ) : (
          ""
        )}

        <div classname="container-fluid mx-0 px-0 bg-dark text-white">
          <div class="bar">
            <span class="bar_content">
              {/* welcome to s-mart online store! free home delivery!. contact @
              9663617620 */}
              {this.context.storeSettings.welcomebarContent}
            </span>
          </div>
        </div>

        {this.context.store &&
        this.context.store.storeTypeId == this.context.storeType.Enterprise ? (
          <></>
        ) : (
          <>
            {this.context.portalMode == "CustomerPortal" ? (
              <>
                {this.context.theme.hideWhatsAppIcon == false ? (
                  <>
                    <a
                      class="whats-app "
                      href={
                        "https://wa.me/" +
                        this.context.storeSettings.defaultCountryCode +
                        this.context.storeSettings.storeWhatsappNo
                      }
                      target="_blank"
                    >
                      <i class="bi bi-whatsapp my-float"></i>
                      {/* <i class="fa fa-whatsapp my-float"></i> */}
                    </a>
                  </>
                ) : (
                  ""
                )}

                <MainNavigationBar SignOut={this.SignOut}></MainNavigationBar>

                {/* {this.context.theme.hideMenu == false ? (
                  <>
                    <SubNavigationBar></SubNavigationBar>
                  </>
                ) : (
                  <></>
                )} */}

                {this.context.showUserLoginNew === true ? (
                  <UserLoginNew
                    show={this.context.showUserLoginNew}
                    onHide={this.HideUserLoginNew}
                  />
                ) : null}
              </>
            ) : this.context.portalMode == "Kiosk" ? (
              <>
                <MainNavigationBar SignOut={this.SignOut}></MainNavigationBar>
                {this.context.showUserLoginNew === true ? (
                  <UserLoginNew
                    show={this.context.showUserLoginNew}
                    onHide={this.HideUserLoginNew}
                  />
                ) : null}
              </>
            ) : (
              <></>
            )}
          </>
        )}

        {/* {this.context.storeSettings.multiCurrencySupport == true &&
        this.context.islanguageSelected == false ? (
          <>
            <LanguageSelection></LanguageSelection>
          </>
        ) : (
          <></>
        )} */}
      </>
    );
  }
}

export const ShopByCategory = (props) => {
  const {
    theme,
    mainCategories,
    subCategories,
    GetProductsByMainCategoryId,
    GetProductsBySubCategoryId,
    UpdateReloadProductListView,
  } = useContext(CartContext);
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);
  const [subCategoryClicked, setSubCategoryClicked] = useState(false);
  const { anchorProps, hoverProps } = useHover(isOpen, setOpen);

  const onMainCategoryClickEvent = (event) => {
    UpdateReloadProductListView("true");
    GetProductsByMainCategoryId(event.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };

  const OnSubCategoryClickEvent = (e) => {
    UpdateReloadProductListView("true");
    GetProductsBySubCategoryId(e.syntheticEvent.currentTarget.id, null, null);
    props.SubCategoryClicked();
  };
  return (
    <>
      <div ref={ref} {...anchorProps}>
        <Button
          className={"dropdown-toggle my-3 border-0 bold_font"}
          style={{
            backgroundColor: "transparent",
            color: theme.mainNavBarTextColor,
          }}
        >
          {/* Shop By Category */}
          <img
            src="/images/menu3.png"
            className="menumobile m-0 p-0 "
            width={25}
            height={25}
            style={{
              filter: "invert(" + theme.invertMenuIconOnMobile + "%)",
            }}
          ></img>
        </Button>
      </div>

      <div
        style={{
          fontFamily: "Raleway Bold",
          fontSize: "16px",
        }}
      >
        <ControlledMenu
          {...hoverProps}
          state={isOpen ? "open" : "closed"}
          anchorRef={ref}
          onClose={() => setOpen(false)}
        >
          {mainCategories.map((maincategory) => (
            <>
              <SubMenu
                label={maincategory.name}
                className="my-2 fw-bold"
                id={maincategory.id}
              >
                {subCategories
                  .filter(
                    (subcategory) =>
                      subcategory.mainCategoryId == maincategory.id
                  )
                  .map((subcategory) => (
                    <>
                      <Link
                        to="/ProductsList"
                        style={{ textDecoration: "none" }}
                      >
                        <MenuItem
                          id={subcategory.id}
                          onClick={(e) => {
                            OnSubCategoryClickEvent(e);
                          }}
                          style={{
                            fontWeight: "bold",
                            color: "black",
                          }}
                        >
                          {subcategory.name}
                        </MenuItem>
                      </Link>
                    </>
                  ))}
              </SubMenu>
            </>
          ))}
        </ControlledMenu>
      </div>
    </>
  );
};

class SubNavigationBar extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showOverlay: false,
      redirect: null,
      showOverlayCategoryMenu: false,
    };
  }

  handleOnMouseEnter = () => {
    //setShowOverlay(true);
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    //setShowOverlay(false);
    this.setState({ showOverlay: false });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      redirect: "/",
    });
  };
  handleOnMouseEnterCategoryMenu = () => {
    //setShowOverlay(true);
    this.setState({ showOverlayCategoryMenu: true });
  };
  handleOnMouseLeaveCategoryMenu = () => {
    //setShowOverlay(false);
    this.setState({ showOverlayCategoryMenu: false });
  };
  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getSubCategoryById = (id) => {
    var pg = this.context.subCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getProductGroupById = (id) => {
    var pg = this.context.productGroups.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  }
  GetMenuName = (menu) => {
    let name = "";
    if (menu.menutype == 1) {
      //main category
      name = this.getMainCategoryById(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category
      name = this.getSubCategoryById(menu.subCategoryId);
    } else if (menu.menutype == 3) {
      name = this.getProductGroupById(menu.productGroupId);
    }

    return name;
  };
  GetSubCategories = (menu) => {
    var result = [];
    if (menu.menutype == 1) {
      //main category
      result = this.context.subCategories.filter(
        (f) => f.mainCategoryId == menu.mainCategoryId
      );
      return result;
    } else return result;
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.menutype == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.menutype == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };
  componentDidMount = () => {};

  render() {
    return (
      <>
        <Navbar
          className="CornersRoundedMenu justify-content-center m-0 p-0"
          style={
            {
              // backgroundColor: this.context.theme.menuNavBarbackgroundColor,
              // backgroundColor: this.context.theme.mainNavBarBackgroundColor,
            }
          }
        >
          <Nav
            className="mx-3 border-0 "
            // style={{ fontFamily: "Raleway Bold" }}
          >
            <Navbar.Collapse
              className="justify-content-center border-0 "
              style={{
                fontSize: this.context.theme.menuFontSize + "px",
                // color: this.context.theme.menuTextColor,
                color: this.context.theme.mainNavBarTextColor,
                fontFamily: "Raleway Bold",
              }}
            >
              {this.context.mainMenus
                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                .map((menu) => (
                  <>
                    {menu.menutype == 1 ? (
                      <>
                        <NavDropdown
                          title={
                            <span
                              className="my-auto"
                              style={{
                                fontSize:
                                  this.context.theme.menuFontSize + "px",
                                color: this.context.theme.mainNavBarTextColor,
                              }}
                            >
                              {this.GetMenuName(menu)}
                            </span>
                          }
                          id={menu.id}
                          className="mx-4"
                          onClick={(e) => {}}
                        >
                          {this.GetSubCategories(menu).map((m) => (
                            <NavDropdown.Item
                              as={Link}
                              id={m.id}
                              style={{
                                fontSize:
                                  this.context.theme.menuFontSize + "px",

                                // color: this.context.theme.mainNavBarTextColor,
                              }}
                              to="/ProductListViewBySubCategory"
                              exact
                              onClick={(e) => {
                                this.context.UpdateReloadProductListView(
                                  "true"
                                );
                                this.context.GetProductsBySubCategoryId(
                                  m.id,
                                  null,
                                  null
                                );
                              }}
                            >
                              {m.name}
                            </NavDropdown.Item>
                          ))}
                        </NavDropdown>
                      </>
                    ) : (
                      <>
                        <Nav.Link
                          as={Link}
                          id={menu.id}
                          className="menuhover menufont mx-4"
                          style={{
                            fontSize: this.context.theme.menuFontSize + "px",
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                          to={this.GetRedirectPathForProductMenu(menu)}
                          exact
                          onClick={(e) => {
                            this.OnMenuClick(e, menu);
                          }}
                        >
                          {this.GetMenuName(menu)}
                        </Nav.Link>
                      </>
                    )}
                  </>
                ))}
            </Navbar.Collapse>
          </Nav>
        </Navbar>
      </>
    );
  }
}

class MainNavigationBar extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      showOverlay: false,
      showOverlayCategoryMenu: false,
      showWishList: false,
      showCartOffCanvas: false,
      countryChanged: false,
      proceedToCheckOut: false,
      proceedToQuote: false,
    };
  }

  handleOnMouseEnter = () => {
    //setShowOverlay(true);
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    //setShowOverlay(false);
    this.setState({ showOverlay: false });
  };

  handleChange = (e) => {
    e.preventDefault();
    this.setState({
      redirect: "/",
    });
  };

  componentDidMount() {
    console.log(this.context.userdata);
  }
  componentDidUpdate() {
    // this.context.refreshbearertoken(
    //   this.context.userdata.emailId,
    //   this.context.userdata.password
    // );
  }
  OnClickWishList = (e) => {
    this.setState({ showWishList: true });
  };
  onCloseWishList = (e) => {
    this.setState({ showWishList: false });
  };
  OnClickCartOffCanvas = (e) => {
    this.setState({ showCartOffCanvas: true });
  };
  onCloseCartOffCanvas = (e) => {
    this.setState({ showCartOffCanvas: false });
    this.context.SetRefreshIncDecControl(true);
  };

  handleOnMouseEnterCategoryMenu = () => {
    //setShowOverlay(true);
    this.setState({ showOverlayCategoryMenu: true });
  };
  handleOnMouseLeaveCategoryMenu = () => {
    //setShowOverlay(false);
    this.setState({ showOverlayCategoryMenu: false });
  };
  getMainCategoryById = (id) => {
    var pg = this.context.mainCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getSubCategoryById = (id) => {
    var pg = this.context.subCategories.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  getProductGroupById = (id) => {
    var pg = this.context.productGroups.filter((e) => e.id === id);
    return pg.length > 0 ? pg[0].name : "";
  };
  GetRedirectPathForProductMenu(menu) {
    if (menu.productGroupId != null) return "/ProductListViewByProductGroup";
    if (menu.mainCategoryId != null) return "/ProductListViewByMainCategory";
    if (menu.subCategoryId != null) return "/ProductListViewBySubCategory";
  }
  GetMenuName = (menu) => {
    let name = "";
    if (menu.menutype == 1) {
      //main category
      name = this.getMainCategoryById(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category
      name = this.getSubCategoryById(menu.subCategoryId);
    } else if (menu.menutype == 3) {
      name = this.getProductGroupById(menu.productGroupId);
    }

    return name;
  };

  OnMenuClick = (e, menu) => {
    this.context.UpdateReloadProductListView("true");
    if (menu.menutype == 1) {
      //main category
      this.context.GetProductsByMainCategoryId(menu.mainCategoryId);
    } else if (menu.menutype == 2) {
      //sub category

      this.context.GetProductsBySubCategoryId(menu.subCategoryId, null, null);
    } else if (menu.menutype == 3) {
      this.context.GetProductsByProductgroupId(menu.productGroupId);
    }
  };

  SignOut = () => {
    this.props.SignOut();
  };

  OnLogin = () => {
    this.context.ShowUserLoginNew(true);
  };
  SubCategoryClicked = () => {
    this.setState({ searchquery: "" }, () => {
      this.setState({ searchData: [] });
    });
    this.setState({ showProductListByCategory: true });
  };
  LogoClick = (e) => {
    if (
      window.location.pathname == "/ProductsList" ||
      window.location.pathname == "/ProductListViewByMainCategory"
    ) {
      var cate = this.context.mainCategories.filter((f) =>
        f.name.includes(e.currentTarget.id)
      );
      if (cate.length > 0) {
        this.context.GetProductsByMainCategoryId(cate[0].id);
        this.context.SetSelectedGreenCategory(e.currentTarget.id);
      }
    } else {
      this.context.SetSelectedGreenCategory(e.currentTarget.id);
    }
  };
  GetRedirectPath = () => {
    if (this.context.prevPage == "Home") return "/HomeGreen1";
    var result =
      window.location.pathname == "/ProductsList"
        ? "/ProductListViewByMainCategory"
        : window.location.pathname == "/ProductListViewByMainCategory"
        ? "/ProductsList"
        : window.location.pathname == "/Home"
        ? "/HomeGreen1"
        : window.location.pathname == "/HomeGreen1"
        ? "/Home"
        : "/HomeGreen1";
    return result;
  };

  onCountryChange = (e) => {
    this.context.SetSelectedCountryCode(e.target.value);
    this.setState({ countryChanged: true }, () => {
      this.setState({ countryChanged: false });
    });
  };

  render() {
    if ((window.location.hostname == "shop.green.com.pg") == false) {
      if (this.state.showProductListByCategory == true) {
        this.setState({ showProductListByCategory: false });
        return <Navigate to="/ProductsList" />;
      }
    }

    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToCheckOut == true
    ) {
      this.setState({ proceedToCheckOut: false }, () => {});
      return <Navigate to="/PayView" />;
    }

    if (
      this.context.userData.loginStatus === "active" &&
      this.state.proceedToQuote == true
    ) {
      this.setState({ proceedToQuote: false }, () => {});
      return <Navigate to="/QuotePage" />;
    }

    return (
      <>
        {this.state.showWishList ? (
          <WishListView onCloseWishList={this.onCloseWishList}></WishListView>
        ) : (
          ""
        )}

        {this.state.showCartOffCanvas ? (
          <CartOffCanvas
            onCloseCartOffCanvas={this.onCloseCartOffCanvas}
          ></CartOffCanvas>
        ) : (
          ""
        )}

        <Navbar
          className="position-sticky fixed-top mx-0 p-2 ui_nav "
          style={{
            backgroundColor:
              this.context.theme.mainNavBarBackgroundColor == undefined
                ? "white"
                : this.context.theme.mainNavBarBackgroundColor,
            opacity:
              window.location.hostname == "shop.green.com.pg" ? "1.0" : "0.8",
          }}
        >
          {window.location.hostname == "shop.green.com.pg" ? (
            <>
              <Col className="mx-5">
                <Navbar.Collapse className="justify-content-start mx-1">
                  <Nav>
                    <Nav.Link
                      as={Link}
                      className=" navBarLink menufont"
                      to="/home"
                      // onClick={(e) => this.context.storePreviousPage("Home")}
                    >
                      <BsFillHouseDoorFill
                        size={"20px"}
                        style={{
                          color: "#23B14D",
                          // this.context.theme.mainNavBarTextColor == undefined
                          //   ? "white"
                          //   : this.context.theme.mainNavBarTextColor,
                        }}
                      ></BsFillHouseDoorFill>
                    </Nav.Link>
                  </Nav>
                  <Nav className="border-0 mx-5 p-0">
                    <a
                      // className="logohover mx-5 p-0 border-0"
                      class="tab-1 navProductMainmenu"
                    >
                      <img
                        src="dist/grnimages/products/sunshine-logo.png"
                        className={"logohover m-0 p-0  "}
                        style={{
                          cursor: "pointer",
                          color: this.context.theme.mainNavBarTextColor,
                          width: "5cm",
                          backgroundColor: "transparant",
                        }}
                      />
                    </a>

                    <div
                      className=""
                      style={{
                        display: "flex",
                        verticalAlign: "bottom",
                        alignItems: "flex-end",
                      }}
                    >
                      <a
                        to="/home"
                        class="navSubmenu active"
                        id="sunshineTab"
                        style={{
                          opacity: "0.5",
                        }}
                      >
                        <img
                          src="dist/grnimages/products/sunshine-logo.png"
                          className={"logohover m-0 p-0"}
                          style={{
                            verticalAlign: "bottom",
                            cursor: "pointer",
                            color: this.context.theme.mainNavBarTextColor,
                            width: "3.5cm",
                            backgroundColor: "transparant",
                            filter:
                              "invert(" + this.context.theme.invertLogo + "%)",
                          }}
                        />
                      </a>
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        verticalAlign: "bottom",
                        alignItems: "flex-end",
                      }}
                    >
                      <a
                        to="/home"
                        class="navSubmenu"
                        id="empawaTab"
                        style={{
                          opacity: "0.5",
                        }}
                      >
                        <img
                          src="dist/grnimages/products/empower-logo.png"
                          className={"logohover m-0 p-0"}
                          style={{
                            verticalAlign: "bottom",
                            cursor: "pointer",
                            color: this.context.theme.mainNavBarTextColor,
                            width: "3.5cm",
                            backgroundColor: "transparant",
                            filter:
                              "invert(" + this.context.theme.invertLogo + "%)",
                          }}
                        />
                      </a>
                    </div>
                    <div
                      className=""
                      style={{
                        display: "flex",
                        verticalAlign: "bottom",
                        alignItems: "flex-end",
                      }}
                    >
                      <a
                        to="/home"
                        class="navSubmenu"
                        id="sunsmartTab"
                        style={{
                          opacity: "0.5",
                        }}
                      >
                        <img
                          src="dist/grnimages/products/sun-smart-logo.png"
                          className={"logohover m-0 p-0"}
                          style={{
                            verticalAlign: "bottom",
                            cursor: "pointer",
                            color: this.context.theme.mainNavBarTextColor,
                            width: "3.5cm",
                            backgroundColor: "transparant",
                            filter:
                              "invert(" + this.context.theme.invertLogo + "%)",
                          }}
                        />
                      </a>
                    </div>
                  </Nav>
                </Navbar.Collapse>
              </Col>
              <Col></Col>
            </>
          ) : (
            <>
              {this.context.portalMode == "CustomerPortal" ? (
                <>
                  <Col xs={3}>
                    <Navbar.Collapse className="justify-content-center border-0 ">
                      {this.context.theme.hideShopByCategoryMenu == false ? (
                        <Nav className="border-0 m-0 p-0">
                          <ShopByCategory
                            SubCategoryClicked={this.SubCategoryClicked}
                          ></ShopByCategory>
                        </Nav>
                      ) : (
                        ""
                      )}{" "}
                      {this.context.theme.hideHomeIcon == false ? (
                        <>
                          &nbsp;&nbsp;
                          <Nav.Link
                            as={Link}
                            className=" navBarLink menufont"
                            to="/Home"
                          >
                            <BsFillHouseDoorFill
                              size={"20px"}
                              style={{
                                color:
                                  this.context.theme.mainNavBarTextColor ==
                                  undefined
                                    ? "white"
                                    : this.context.theme.mainNavBarTextColor,
                              }}
                            ></BsFillHouseDoorFill>
                          </Nav.Link>
                        </>
                      ) : (
                        <></>
                      )}
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Nav className="border-0 m-0 p-0">
                        <Nav.Link
                          as={Link}
                          to="/home"
                          className="logohover m-0 p-0 border-0"
                        >
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.storeSettings.storeLogoFileName +
                              this.context.store.storageSasToken
                            }
                            className={"logohover m-0 p-0  "}
                            style={{
                              cursor: "pointer",
                              color: this.context.theme.mainNavBarTextColor,
                              width:
                                this.context.theme.storeLogoImageWidth ==
                                undefined
                                  ? "1cm"
                                  : this.context.theme.storeLogoImageWidth +
                                    "cm",
                              backgroundColor: "transparant",
                              filter:
                                "invert(" +
                                this.context.theme.invertLogo +
                                "%)",
                            }}
                          />
                        </Nav.Link>
                      </Nav>
                    </Navbar.Collapse>
                  </Col>
                  {this.context.theme.allProductToGetQuote == true ? (
                    <>
                      <Col xs={9}>
                        <Navbar.Collapse className="justify-content-end  border-0">
                          <Nav className="mx-1">
                            <SearchView></SearchView>&nbsp;&nbsp;
                          </Nav>
                          <Nav.Link
                            as={Link}
                            className="mx-1"
                            size="lg"
                            style={{
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                            }}
                            to="/home"
                          >
                            Home
                          </Nav.Link>
                          <Nav.Link
                            as={Link}
                            className="mx-1"
                            size="lg"
                            style={{
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                            }}
                            to="/ProductsList"
                            onClick={(e) => {
                              this.context.ClearProductListViewType();
                            }}
                          >
                            Products
                          </Nav.Link>
                          <Nav.Link
                            as={Link}
                            className="mx-1"
                            size="lg"
                            style={{
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                            }}
                            to="/AboutUs"
                          >
                            About us
                          </Nav.Link>
                          <Nav.Link
                            as={Link}
                            className="mx-1"
                            size="lg"
                            style={{
                              // backgroundColor: "#00ff00",
                              // color: "black",
                              // backgroundColor: "#00ff00",
                              // color: "black",
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                            }}
                            to="/QueriesPage"
                          >
                            {/* <FaQuestion size={16} /> */}
                            &nbsp;Contact us
                          </Nav.Link>
                          {/* <Button
                            as={Link}
                            className="mx-1 border-0"
                            size="lg"
                            style={{
                              backgroundColor: "#00ff00",
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                              // width: "150px",
                            }}
                            //to="/QuotePage"
                            onClick={(e) => {
                              if (
                                this.context.userData.loginStatus != "active"
                              ) {
                                this.OnLogin();
                              } else {
                                this.setState({ proceedToQuote: true });
                              }
                            }}
                          >
                            <TbCoinRupee size={22} />
                            &nbsp;Get Quote
                          </Button> */}
                          <Nav.Link
                            className="mx-1 text-center border-1 border-dark"
                            onClick={(e) => this.OnClickWishList(e)}
                            style={{
                              backgroundColor: "#00ff00",
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                              // width: "150px",
                            }}
                          >
                            <BsFillHeartFill
                              size={"18px"}
                              style={{
                                color: "red",
                              }}
                            ></BsFillHeartFill>
                            &nbsp;My Wishlist
                            <Badge
                              bg={
                                this.context.theme.badgeBackgroundColor ==
                                undefined
                                  ? "white"
                                  : this.context.theme.badgeBackgroundColor
                              }
                              className="notify-badge-quote-wishlist"
                              style={{
                                color: this.context.theme.badgeTextColor,
                              }}
                            >
                              {this.context.wishListProductIds.length}
                            </Badge>
                          </Nav.Link>

                          <Nav.Link
                            // as={Link}
                            className="mx-1 "
                            style={{
                              backgroundColor: "#00ff00",
                              color: "black",
                              fontSize: "18px",
                              fontFamily: "Helvetica",
                              // width: "150px",
                            }}
                            onClick={(e) => {
                              if (
                                this.context.userData.loginStatus != "active"
                              ) {
                                this.OnLogin();
                              } else {
                                this.setState({ proceedToCheckOut: true });
                              }
                            }}
                            // to="/PayView"
                          >
                            <BsCash size={22} color="red" /> &nbsp;My Orders
                          </Nav.Link>

                          <Nav className="mx-1 ">
                            <Button
                              as={Link}
                              className="border py-2"
                              hidden={this.context.userData.hideSignupLink}
                              onClick={this.OnLogin}
                              style={{
                                backgroundColor: "#180A54",
                                color: "white",

                                fontSize: "18px",
                                fontFamily: "Helvetica",
                                width: "150px",
                              }}
                            >
                              <BsPerson
                                size={22}
                                style={
                                  {
                                    // color: this.context.theme.mainNavBarTextColor,
                                  }
                                }
                              ></BsPerson>
                              &nbsp;My Account
                            </Button>
                            {/* User */}
                            <OverlayTrigger
                              show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                              key="bottom"
                              placement="bottom"
                              rootClose="false"
                              overlay={
                                <Popover
                                  id={`popover-positioned-bottom`}
                                  onMouseEnter={this.handleOnMouseEnter}
                                  onMouseLeave={this.handleOnMouseLeave}
                                  className="navDsn"
                                >
                                  <Popover.Header as="h3"></Popover.Header>
                                  <Popover.Body>
                                    <UserMenu SignOut={this.SignOut}></UserMenu>
                                  </Popover.Body>
                                </Popover>
                              }
                            >
                              <Button
                                className="border py-2"
                                hidden={!this.context.userData.hideSignupLink}
                                onMouseEnter={this.handleOnMouseEnter}
                                onMouseLeave={this.handleOnMouseLeave}
                                style={{
                                  backgroundColor: "#180A54",
                                  color: "white",
                                  // backgroundColor: "white",
                                  // color: "black",
                                  fontSize: "18px",
                                  fontFamily: "Helvetica",
                                  width: "150px",
                                }}
                              >
                                <BsPerson
                                  size={22}
                                  style={
                                    {
                                      // color:
                                      //   this.context.theme.mainNavBarTextColor,
                                    }
                                  }
                                ></BsPerson>
                                &nbsp;My Account
                              </Button>
                            </OverlayTrigger>
                          </Nav>
                        </Navbar.Collapse>
                      </Col>
                    </>
                  ) : (
                    <>
                      <Col xs={7} className="">
                        <Row>
                          {this.context.theme.hideMenu == false ? (
                            <>
                              <SubNavigationBar></SubNavigationBar>
                            </>
                          ) : (
                            <></>
                          )}
                        </Row>
                        {window.location.hostname == "lifestyleaqua.com" ||
                        window.location.hostname == "www.lifestyleaqua.com" ? (
                          <>
                            <Row className="">
                              <Nav
                                className="border-0 m-0 p-0 justify-content-center "
                                style={{}}
                              >
                                <Nav.Link
                                  as={Link}
                                  to="/LifeStyleAquaAuthenticator"
                                  className="logohover my-1"
                                  style={{
                                    color:
                                      this.context.theme.mainNavBarTextColor,
                                    paddingRight: "30px",
                                  }}
                                >
                                  <i>
                                    <u>Arowana Authenticator</u>
                                  </i>
                                </Nav.Link>
                              </Nav>
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                      </Col>
                    </>
                  )}
                </>
              ) : (
                <></>
              )}
            </>
          )}
          {this.context.portalMode == "CustomerPortal" &&
          this.context.theme.allProductToGetQuote != true ? (
            <>
              <Col xs={window.location.hostname == "shop.green.com.pg" ? 3 : 2}>
                <Navbar.Collapse className="justify-content-end border-0">
                  <Nav className="mx-1">
                    <SearchView></SearchView>&nbsp;&nbsp;
                  </Nav>
                  <Nav className="mx-2">
                    {/* Signup/Login */}
                    <Nav.Link
                      as={Link}
                      className="py-3 "
                      to=""
                      hidden={this.context.userData.hideSignupLink}
                      onClick={this.OnLogin}
                    >
                      <BsPerson
                        size={"22px"}
                        style={{
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                      ></BsPerson>
                      {/* SignUp/Login */}
                    </Nav.Link>
                    {/* User */}
                    <OverlayTrigger
                      show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                      key="bottom"
                      placement="bottom"
                      rootClose="false"
                      overlay={
                        <Popover
                          id={`popover-positioned-bottom`}
                          onMouseEnter={this.handleOnMouseEnter}
                          onMouseLeave={this.handleOnMouseLeave}
                          // onClick={this.handleOnMouseEnter}
                          className="navDsn"
                        >
                          <Popover.Header as="h3"></Popover.Header>
                          <Popover.Body>
                            <UserMenu SignOut={this.SignOut}></UserMenu>
                          </Popover.Body>
                        </Popover>
                      }
                    >
                      <Button
                        className=""
                        variant=""
                        hidden={!this.context.userData.hideSignupLink}
                        onMouseEnter={this.handleOnMouseEnter}
                        onMouseLeave={this.handleOnMouseLeave}
                        // onClick={this.handleOnMouseEnter}
                      >
                        <BsPerson
                          size={"22px"}
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        ></BsPerson>
                      </Button>
                    </OverlayTrigger>
                  </Nav>
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <></>
                  ) : (
                    <>
                      <Nav className="mx-2 ">
                        <Nav.Link
                          className="border-0 text-success text-center"
                          onClick={(e) => this.OnClickWishList(e)}
                        >
                          <BsHeart
                            size={"18px"}
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsHeart>
                          <Badge
                            bg={
                              this.context.theme.badgeBackgroundColor ==
                              undefined
                                ? "white"
                                : this.context.theme.badgeBackgroundColor
                            }
                            className="notify-badge-wishlist "
                            style={{
                              color: this.context.theme.badgeTextColor,
                            }}
                          >
                            {this.context.wishListProductIds.length}
                          </Badge>
                        </Nav.Link>
                      </Nav>
                    </>
                  )}
                  <Nav className="m-0 p-0">
                    <Nav.Link
                      // as={Link}
                      className="verticalaligncentertd "
                      style={{ color: "black" }}
                      // to="/Cart"
                      onClick={(e) => this.OnClickCartOffCanvas(e)}
                    >
                      {this.context.theme.cartIcon == "Cart" ||
                      this.context.theme.cartIcon == undefined ? (
                        <>
                          <BsCart2
                            size={"20px"}
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsCart2>
                        </>
                      ) : (
                        <>
                          <BsBag
                            size={"20px"}
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsBag>
                        </>
                      )}
                      {window.location.hostname == "shop.green.com.pg" ? (
                        <>
                          <span
                            class="badge"
                            className="notify-badge-cart"
                            style={{
                              color: this.context.theme.badgeTextColor
                                ? this.context.theme.badgeTextColor
                                : "white",
                              backgroundColor: "#23B14D",
                            }}
                          >
                            {this.context.getTotalQtyInCart(
                              this.context.myCart
                            )}
                          </span>
                        </>
                      ) : (
                        <>
                          <Badge
                            bg={
                              this.context.theme.badgeBackgroundColor ==
                              undefined
                                ? "white"
                                : this.context.theme.badgeBackgroundColor
                            }
                            className="notify-badge-cart"
                            style={{
                              color: this.context.theme.badgeTextColor,
                            }}
                          >
                            {this.context.getTotalQtyInCart(
                              this.context.myCart
                            )}
                          </Badge>
                        </>
                      )}
                    </Nav.Link>
                  </Nav>
                  {this.context.storeSettings.multiCurrencySupport == true ? (
                    <>
                      <Nav className="mx-2 ">
                        <span
                          style={{
                            color: this.context.theme.mainNavBarTextColor,
                          }}
                        >
                          <Stack direction="horizontal">
                            <label className="text-center">
                              <small>Country:</small>
                            </label>
                            &nbsp;
                            <Form.Select
                              type="text"
                              name="countryName"
                              value={this.context.selectedCountryCode}
                              required
                              className="text-start"
                              autoComplete="off"
                              onChange={(e) => this.onCountryChange(e)}
                            >
                              {this.context.multiCurrenciesSetting &&
                                this.context.multiCurrenciesSetting
                                  .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                  .map((m) => (
                                    <>
                                      <option value={m.countryName}>
                                        {m.countryName}
                                      </option>
                                    </>
                                  ))}
                            </Form.Select>
                          </Stack>
                        </span>
                      </Nav>
                    </>
                  ) : (
                    <></>
                  )}
                </Navbar.Collapse>
              </Col>
            </>
          ) : (
            <></>
          )}
          {this.context.portalMode == "Kiosk" ? (
            <>
              <Col>
                <Navbar.Collapse className="justify-content-start m-0 p-0 ">
                  <Nav className="border-0 m-0 p-0 verticalaligncentertd">
                    <Nav.Link
                      as={Link}
                      className=" navBarLink menufont "
                      to="/Kiosk"
                      // onClick={(e) => this.context.storePreviousPage("Home")}
                    >
                      <BsHouse
                        size={"35px"}
                        style={{
                          color: "green",
                          // this.context.theme.mainNavBarTextColor == undefined
                          //   ? "white"
                          //   : this.context.theme.mainNavBarTextColor,
                        }}
                      ></BsHouse>
                    </Nav.Link>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <Nav.Link
                      as={Link}
                      to="/Kiosk"
                      className="logohover m-0 p-0 border-0"
                    >
                      {/* &nbsp;&nbsp; */}
                      <img
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          this.context.storeSettings.storeLogoFileName +
                          this.context.store.storageSasToken
                        }
                        //className="logohover invertimage m-0 p-0"
                        className={"logohover m-0 p-0  "}
                        style={{
                          cursor: "pointer",
                          color: this.context.theme.mainNavBarTextColor,
                          width:
                            this.context.theme.storeLogoImageWidth == undefined
                              ? "1cm"
                              : this.context.theme.storeLogoImageWidth + "cm",
                          backgroundColor: "transparant",
                          filter:
                            "invert(" + this.context.theme.invertLogo + "%)",
                        }}
                      />
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Col>
              <Col>
                <Navbar.Collapse className="justify-content-end">
                  <Nav className="m-0 p-0">
                    <Nav.Link
                      // as={Link}
                      className="verticalaligncentertd "
                      style={{ color: "black" }}
                      // to="/Cart"
                      onClick={(e) => this.OnClickCartOffCanvas(e)}
                    >
                      {this.context.theme.cartIcon == "Cart" ? (
                        <>
                          <b>My Cart</b> &nbsp;&nbsp;&nbsp;
                          <BsCart2
                            size={"30px"}
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsCart2>
                        </>
                      ) : (
                        <>
                          <b>My Cart</b> &nbsp;&nbsp;&nbsp;
                          <BsBag
                            size={"30px"}
                            style={{
                              color: this.context.theme.mainNavBarTextColor,
                            }}
                          ></BsBag>
                        </>
                      )}
                      <Badge
                        bg={
                          this.context.theme.badgeBackgroundColor == undefined
                            ? "white"
                            : this.context.theme.badgeBackgroundColor
                        }
                        className="notify-badge-cart"
                        style={{
                          color: this.context.theme.badgeTextColor,
                        }}
                      >
                        {this.context.getTotalQtyInCart(this.context.myCart)}
                      </Badge>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Navbar>
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const currentUrl = window.location.href;
    const parts = currentUrl.split(/\/+/).filter((part) => part !== "");
    return (
      <>
        <Row className="bg-whie">
          <label className="bg-whie">
            <h4>
              Hi{" "}
              {this.context.userData.firstName
                ? this.context.userData.firstName
                : "Guest!"}
            </h4>
          </label>
        </Row>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          <ListGroup.Item
            className={parts[2] === "UserOrders" ? "navActive" : ""}
            as={Link}
            action
            variant="dark"
            to={
              this.context.theme.allProductToGetQuote == true
                ? "/PayView"
                : "/UserOrders"
            }
          >
            My Orders <i class="bi bi-arrow-right  me-2"></i>
          </ListGroup.Item>
          <ListGroup.Item
            className={parts[2] === "UserProfile" ? "navActive" : ""}
            as={Link}
            action
            variant="dark"
            to="/UserProfile"
          >
            My Profile <i class="bi bi-arrow-right  me-2"></i>
          </ListGroup.Item>
          {/* <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/UserChangePassword"
          >
            Change Password <i class="bi bi-arrow-right  me-2"></i>
          </ListGroup.Item> */}
          {/* <ListGroup.Item variant="dark" className="bg-dark"></ListGroup.Item> */}
          <ListGroup.Item
            as={Link}
            className={parts[2] === "UserCustomerSupport" ? "navActive" : ""}
            action
            variant="dark"
            to="/UserCustomerSupport"
          >
            Customer Support <i class="bi bi-arrow-right  me-2"></i>
          </ListGroup.Item>
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              Swal.fire({
                title: "Warning!",
                confirmButtonColor: "#23B14D",
                cancelButtonColor: "#dbdbdb",
                text: "Are you sure you want to Signout?",
                showCancelButton: "true",
                cancelButtonText: "No",
                confirmButtonText: "Yes",
              }).then((result) => {
                if (result.isConfirmed) {
                  this.context.clearUserData();
                  this.props.SignOut();
                }
              });
            }}
          >
            Sign Out <i class="bi bi-arrow-right  me-2"></i>
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}
