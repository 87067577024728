import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

export class AboutUs extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div class="container-xxl py-5">
          <div class="container">
            <div class="row g-5 align-items-center">
              <h1 class="mb-0">Welcome to bagfactry.com</h1>
              <div
                class="col-lg-12 wow fadeIn"
                data-wow-delay="0.5s"
                style={{ fontFamily: "Raleway" }}
              >
                <h3>
                  <b>
                    The Heart of Craftsmanship: The Story of Maicabagfactory
                  </b>
                </h3>
                <p>
                  In the bustling world of fashion and functionality,
                  Maicabagfactory stands as a beacon of quality and innovation.
                  Our journey began with a simple idea: to create bags that
                  blend style with durability, serving the needs of everyday
                  life while making a statement. Our Origins
                </p>
                <p>
                  Founded in 2017, Maicabagfactory was born from a passion for
                  craftsmanship and a commitment to sustainability. Our founder,
                  MD Abrar, envisioned a place where creativity could thrive,
                  and every bag produced would tell a story. With a small team
                  of skilled artisans, we set up our first workshop, equipped
                  with the finest materials and a drive to make every product
                  unique. The
                </p>
                <h4>
                  <b>Crafting Process</b>
                </h4>
                <p>
                  At Maicabagfactory, every bag is a labor of love. We believe
                  in the beauty of handmade products, where each stitch and seam
                  is carefully crafted. Our artisans combine traditional
                  techniques with modern designs, ensuring that every bag is not
                  just an accessory, but a piece of art.
                </p>
                <ol>
                  <li>
                    {" "}
                    Design: Our creative team sketches designs inspired by the
                    latest trends while considering the practical needs of our
                    customers. Each concept goes through rigorous testing to
                    ensure functionality and style.
                  </li>{" "}
                  <li>
                    Sourcing Materials: We prioritize eco-friendly materials,
                    sourcing premium fabrics that are not only stylish but also
                    sustainable. Our commitment to the environment means we use
                    recycled materials whenever possible.
                  </li>{" "}
                  <li>
                    Production: With skilled hands and an eye for detail, our
                    craftsmen meticulously construct each bag. From cutting to
                    sewing, every step is executed with precision, ensuring
                    quality that stands the test of time.
                  </li>{" "}
                  <li>
                    Quality Assurance: Before a bag leaves our factory, it
                    undergoes a thorough quality check. We believe that our
                    customers deserve nothing less than perfection.
                  </li>{" "}
                </ol>
                <p>
                  <h4>
                    <b>Our Commitment to Community</b>
                  </h4>{" "}
                  Maicabagfactory is more than just a manufacturing facility; we
                  are a part of the community. We employ local artisans and
                  provide them with fair wages and opportunities for skill
                  development. Our goal is to empower individuals while
                  fostering a supportive work environment where creativity can
                  flourish.
                </p>
                <p>
                  <h4>
                    <b>Sustainability at Our Core</b>
                  </h4>{" "}
                  We understand the importance of preserving our planet for
                  future generations. That’s why we actively seek to minimize
                  waste through efficient production processes and a commitment
                  to recycling. Our bags are designed to last, reducing the need
                  for disposable fashion and encouraging a more sustainable
                  lifestyle.
                </p>{" "}
                <p>
                  <h4>
                    <b>The Future of Maicabagfactory</b>
                  </h4>{" "}
                  As we look to the future, Maicabagfactory is excited to expand
                  our collection and reach a broader audience. Our passion for
                  quality and sustainability will continue to drive our
                  innovation as we explore new designs, materials, and
                  technologies. Join us on this journey as we create bags that
                  not only elevate your style but also contribute to a better
                  world. At Maicabagfactory, every bag tells a story—let yours
                  begin today.
                </p>
                {/* <div class="d-flex align-items-center mb-4">
                  <div
                    class="flex-shrink-0 p-4 ms-4"
                    style={{
                      backgroundColor: "#00ff00",
                      color: "white",
                    }}
                  >
                    <h3
                      class="text-black text-center"
                      style={{
                        width: "150px",
                      }}
                    >
                      Our <br />
                      Vision{" "}
                    </h3>
                  </div>
                  <div class="ms-4">
                    <h4 class="">
                      "To be the world's trusted factory for diverse,
                      high-quality Indian products"
                    </h4>
                  </div>
                </div> */}
                {/* <div class="d-flex align-items-center mb-4">
                  <div
                    class="flex-shrink-0 p-4 ms-4"
                    style={{
                      backgroundColor: "#00ff00",
                      color: "white",
                    }}
                  >
                    <h3
                      class="text-center"
                      style={{
                        width: "150px",
                      }}
                    >
                      Our <br />
                      Mission{" "}
                    </h3>
                  </div>
                  <div class="ms-4">
                    <h4 class="">
                      "Simplifying global procurement by offering quality,
                      efficiency, and transparency in sourcing Indian products"
                    </h4>
                  </div>
                </div> */}
                <hr />
                <div class="row pt-2">
                  <div class="col-sm-6">
                    <div
                      class="d-flex align-items-center "
                      className="text-center"
                    >
                      <MdEmail size="48" color="red" />
                      <div class="ms-3">
                        <h3>
                          <p class="mb-2">Email us</p>
                          <p class="mb-0">
                            {this.context.storeSettings.storeEmailId}
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div
                      class="d-flex align-items-center"
                      className="text-center"
                    >
                      <FaPhone size="48" color="red" />
                      <div class="ms-3">
                        <h3>
                          <p class="mb-2">Call us</p>
                          <p class="mb-0">
                            +{this.context.storeSettings.defaultCountryCode}{" "}
                            {this.context.storeSettings.storePhoneNo}
                          </p>
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
