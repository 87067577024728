import React, { Component } from "react";
import { useContext } from "react";
import ProductCard from "./ProductCard";
import RangeSlider from "react-bootstrap-range-slider";
import { MessageBox } from "./MessageBox";
import CartContext from "./CartContext";
import { useState } from "react";
import { LoadSpinner } from "./LoadSpinner";
import { Navigate } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { ProductsListMobile } from "./ProductsListMobile.js";
//import ReactPaginate from "https://cdn.skypack.dev/react-paginate@7.1.3";
import "../CSS/pagination.css";
import ReactPaginate from "react-paginate";
import { RWebShare } from "react-web-share";
import { FaShareAlt } from "react-icons/fa";
import Breadcrumb from "react-bootstrap/Breadcrumb";

import {
  Accordion,
  Row,
  Col,
  Modal,
  Form,
  Offcanvas,
  Table,
  Stack,
  FloatingLabel,
  NavLink,
  Carousel,
  Badge,
  Nav,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";

function demoAsyncCall() {
  return new Promise((resolve) => setTimeout(() => resolve(), 2500));
}

export class SubCategoryPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      subCategories: [],
    };
  }
  componentDidMount = () => {
    this.setState({
      subCategories: this.context.subCategories.filter(
        (f) => f.mainCategoryId == this.context.selectedMainCategoryId
      ),
    });
    window.scrollTo(0, 0);
  };
  render() {
    const { loading } = this.state;

    if (this.context.storeId == "") return <Navigate to="/home" />;

    if (loading) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    return (
      <>
        <div className="mx-0">
          {window.innerWidth > 1200 ? (
            <>
              {this.context.mainCategories.filter(
                (e) => e.id === this.context.selectedMainCategoryId
              )[0].adImgFileName != undefined ? (
                <>
                  <Row>
                    <Col>
                      <div>
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.context.mainCategories.filter(
                              (e) =>
                                e.id === this.context.selectedMainCategoryId
                            )[0].adImgFileName +
                            this.context.store.storageSasToken
                          }
                          width="100%"
                        ></img>
                      </div>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
              <BreadcrumbMainCategory></BreadcrumbMainCategory>
              <div class="title-dsn text-center PY-3">
                <h2
                  class="bold_font"
                  style={{
                    color: this.context.theme.homepageTitlesTextColor,
                  }}
                >
                  {
                    this.context.mainCategories.filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0].name
                  }
                </h2>
              </div>
            </>
          ) : (
            <>
              <div
                class="title-dsn text-center "
                style={{ paddingTop: "70px" }}
              >
                {this.context.mainCategories.filter(
                  (e) => e.id === this.context.selectedMainCategoryId
                )[0].adImgFileName != undefined ? (
                  <>
                    <Row>
                      <Col>
                        <div>
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.context.mainCategories.filter(
                                (e) =>
                                  e.id === this.context.selectedMainCategoryId
                              )[0].adImgFileName +
                              this.context.store.storageSasToken
                            }
                            width="100%"
                          ></img>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                <BreadcrumbMainCategory></BreadcrumbMainCategory>
                <h4
                  class="bold_font"
                  style={{
                    color: this.context.theme.homepageTitlesTextColor,
                  }}
                >
                  {
                    this.context.mainCategories.filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0].name
                  }
                </h4>
              </div>
            </>
          )}

          <div class="mx-5 ">
            <div class="title-dsn text-center">
              <span
                style={{
                  color: this.context.theme.homepageTitlesTextColor,
                }}
              >
                <p
                  style={{
                    fontSize: "16px",
                    textAlign: "justify",
                    fontFamily: "Raleway",
                  }}
                >
                  {
                    this.context.mainCategories.filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0].description
                  }
                </p>
              </span>
            </div>
          </div>
          <div className="row justify-content-around">
            {this.state.subCategories
              .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
              .map((subcategory) => (
                <>
                  <div
                    className="card p-1 mb-5 border-0"
                    style={{
                      width:
                        this.context.theme.maincategoryImageWidth + 2 + "cm",
                      height:
                        this.context.theme.mainCategoryImageHeight + 2 + "cm",
                      backgroundColor: this.context.theme
                        .subCategoryBackgroundColor
                        ? this.context.theme.subCategoryBackgroundColor
                        : this.context.theme.homepageBackgroundColor,
                    }}
                  >
                    <div className="text-center imgcenter img-hover-mover">
                      <Nav.Link
                        as={Link}
                        id={subcategory.id}
                        to={
                          window.innerWidth > 1200
                            ? "/ProductsList"
                            : "/ProductsListMobile"
                        }
                        onClick={(e) => {
                          this.context.GetProductsBySubCategoryId(
                            e.currentTarget.id,
                            null,
                            null
                          );
                        }}
                        style={{
                          color: this.context.theme.subCategoryTextColor
                            ? this.context.theme.subCategoryTextColor
                            : this.context.theme.homepageTitlesTextColor,
                          backgroundColor: this.context.theme
                            .subCategoryBackgroundColor
                            ? this.context.theme.subCategoryBackgroundColor
                            : this.context.theme.homepageBackgroundColor,
                        }}
                      >
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            subcategory.imgFileName +
                            this.context.store.storageSasToken
                          }
                          className={
                            this.context.theme.subCategoryAndBrandDisplayShape
                          }
                          alt="..."
                          style={{
                            cursor: "pointer",
                            width:
                              this.context.theme.maincategoryImageWidth + "cm",
                            height:
                              this.context.theme.mainCategoryImageHeight + "cm",
                          }}
                        ></img>
                        <h6
                          className="text-center my-3"
                          style={{
                            height:
                              this.context.theme.subcategoryNameHeight + "cm",
                            color: this.context.theme.subCategoryTextColor
                              ? this.context.theme.subCategoryTextColor
                              : this.context.theme.homepageTitlesTextColor,
                          }}
                        >
                          <b>{subcategory.name}</b>{" "}
                        </h6>
                      </Nav.Link>
                      <span
                        class="notify-subcategory-badge-right_share"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Share me"
                        style={{
                          cursor: "pointer",
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                      >
                        <RWebShare
                          data={{
                            text: subcategory.name,
                            url:
                              window.location.origin +
                              "/SubCategoryArg?name=" +
                              subcategory.name.replace(/\s/g, "%20"),
                            title: subcategory.name,
                          }}
                          onClick={() => console.log("shared successfully!")}
                        >
                          <FaShareAlt size="16" />
                        </RWebShare>
                      </span>
                    </div>
                  </div>
                </>
              ))}
          </div>
        </div>
      </>
    );
  }
}

export class BreadcrumbMainCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="mx-3 my-3">
        <Breadcrumb>
          <Breadcrumb.Item className="m-0 p-0">
            <Nav.Link as={Link} className="m-0 p-0" to="/home">
              Home
            </Nav.Link>
          </Breadcrumb.Item>
          <Breadcrumb.Item active style={{ display: "inline-block" }}>
            {
              this.context.mainCategories.filter(
                (e) => e.id === this.context.selectedMainCategoryId
              )[0].name
            }
          </Breadcrumb.Item>
          {/* <Breadcrumb.Item active>Data</Breadcrumb.Item> */}
        </Breadcrumb>
      </div>
    );
  }
}

export class BreadcrumbSubCategory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      mainCategory: "",
    };
  }

  componentDidMount = () => {
    // if (this.props.subcategorynamearg) {
    //   var subCategory = this.context.subCategories.filter(
    //     (f) =>
    //       f.name &&
    //       f.name.toUpperCase() == this.props.subcategorynamearg.toUpperCase()
    //   );
    //   if (subCategory.length > 0) {
    //     this.setState({
    //       mainCategory: this.context.mainCategories.filter(
    //         (f) => f.id == subCategory[0].mainCategoryId
    //       )[0],
    //     });
    //   }
    // }
    if (
      this.context.prodListTitle != undefined &&
      this.context.prodListTitle != "" &&
      this.context.productListViewType === "SubCategory"
    ) {
      this.context.SetSelectedMainCategoryId(
        this.context.subCategories.filter(
          (f) => f.name == this.context.prodListTitle
        )[0].mainCategoryId
      );
    }
  };

  render() {
    return (
      <div className="mx-3">
        <Breadcrumb>
          <Breadcrumb.Item className="m-0 p-0">
            <Nav.Link as={Link} className="m-0 p-0" to="/home">
              Home
            </Nav.Link>
          </Breadcrumb.Item>
          {this.context.selectedMainCategoryId != undefined &&
          this.context.selectedMainCategoryId != "" ? (
            <>
              {/* <Breadcrumb.Item
                className="m-0 p-0"
                href={
                  window.location.origin +
                  "/MainCategoryArg?name=" +
                  this.context.mainCategories
                    .filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0]
                    .name.replace(/\s/g, "%20")
                }
              >
                {
                  this.context.mainCategories.filter(
                    (e) => e.id === this.context.selectedMainCategoryId
                  )[0].name
                }
              </Breadcrumb.Item> */}
              <Breadcrumb.Item className="m-0 p-0">
                <Nav.Link
                  className="m-0 p-0"
                  as={Link}
                  id={this.context.selectedMainCategoryId}
                  to="/SubCategoryPage"
                  onClick={(e) => {
                    this.context.SetSelectedMainCategoryId(e.currentTarget.id);
                  }}
                  style={{ display: "inline-block" }}
                >
                  {
                    this.context.mainCategories.filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0].name
                  }
                </Nav.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {this.context.prodListTitle}
              </Breadcrumb.Item>
            </>
          ) : (
            <>
              <Breadcrumb.Item>
                <Nav.Link
                  as={Link}
                  id={this.state.mainCategory.id}
                  to="/SubCategoryPage"
                  onClick={(e) => {
                    this.context.SetSelectedMainCategoryId(e.currentTarget.id);
                  }}
                  style={{ display: "inline-block" }}
                >
                  {this.state.mainCategory.name}
                </Nav.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item active>
                {this.props.subcategorynamearg}
              </Breadcrumb.Item>
            </>
          )}
        </Breadcrumb>
      </div>
    );
  }
}

export class BreadcrumbProduct extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      mainCategory: "",
    };
  }

  componentDidMount = () => {
    if (
      this.context.shopnowProduct != undefined ||
      this.context.shopnowProduct != ""
    ) {
    }
  };

  render() {
    return (
      <div className="mx-3">
        <Breadcrumb>
          <Breadcrumb.Item className="m-0 p-0">
            <Nav.Link as={Link} className="m-0 p-0" to="/home">
              Home
            </Nav.Link>
          </Breadcrumb.Item>
          {this.context.selectedMainCategoryId != undefined &&
          this.context.selectedMainCategoryId != "" ? (
            <>
              <Breadcrumb.Item className="m-0 p-0">
                <Nav.Link
                  className="m-0 p-0"
                  as={Link}
                  id={this.context.selectedMainCategoryId}
                  to="/SubCategoryPage"
                  onClick={(e) => {
                    this.context.SetSelectedMainCategoryId(e.currentTarget.id);
                  }}
                  style={{ display: "inline-block" }}
                >
                  {
                    this.context.mainCategories.filter(
                      (e) => e.id === this.context.selectedMainCategoryId
                    )[0].name
                  }
                </Nav.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item className="m-0 p-0">
                <Nav.Link
                  className="m-0 p-0"
                  as={Link}
                  id={this.context.shopnowProduct.subCategoryId}
                  to={
                    window.innerWidth > 1200
                      ? "/ProductsList"
                      : "/ProductsListMobile"
                  }
                  onClick={(e) => {
                    this.context.GetProductsBySubCategoryId(
                      e.currentTarget.id,
                      null,
                      null
                    );
                  }}
                  style={{ display: "inline-block" }}
                >
                  {this.context.shopnowProduct.subCategoryName}
                </Nav.Link>
              </Breadcrumb.Item>
              <Breadcrumb.Item
                className="m-0 p-0"
                active
                style={{ display: "inline-block" }}
              >
                {this.context.shopnowProduct.name}
              </Breadcrumb.Item>
            </>
          ) : (
            <></>
          )}
        </Breadcrumb>
      </div>
    );
  }
}
