import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { FaPhone } from "react-icons/fa";
import { MdEmail } from "react-icons/md";
import { Country, State, City } from "country-state-city";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import { Form, FloatingLabel, Row, Col, Button } from "react-bootstrap";

export class QueriesPage extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      lead: {
        status: 1,
        customerType: 1,
        nextFollowUpDate: new Date(),
        submitted: false,
      },
    };
  }

  AddOrUpdateLeadInDB = () => {
    var tmpLead = this.state.lead;
    tmpLead.storeId = this.context.storeId;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpLead),
    };

    fetch(
      process.env.REACT_APP_API + "ManufactureAndService/AddOrUpdateSalesLead/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: "Information!",
            confirmButtonColor: "#23B14D",
            text: "Thank you. We have received your query. Our Team would get in touch with you shortly! ",
            confirmButtonText: "Ok",
          }).then((result) => {
            this.setState({ submitted: true });
          });

          return;
        }
        alert("This Lead is already exist. try again!");
        return;
      })
      .catch((error) => {
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateLeadInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "customerName":
        this.state.lead.customerName = value;
        break;
      case "mobileNo":
        this.state.lead.mobileNo = value;
        break;
      case "emailId":
        this.state.lead.emailId = value;
        break;
      case "address":
        this.state.lead.address = value;
        break;
      case "landMark":
        this.state.lead.landMark = value;
        break;
      case "city":
        this.state.lead.city = value;
        break;
      case "state":
        this.state.lead.state = value;
        break;
      case "country":
        this.state.lead.country = value;
        break;
      case "description":
        this.state.lead.description = value;
        break;
    }
    this.setState({ lead: this.state.lead });
  };

  onNextFollowupChange = (date) => {
    this.state.lead.nextFollowUpDate = date;
    this.setState({
      lead: this.state.lead,
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.Type == "Edit") {
      this.setState({ lead: this.props.lead });
    }
  }

  render() {
    if (this.state.submitted == true) return <Navigate to="/home" />;

    return (
      <>
        <div class="container my-3">
          <div className="text-center my-5">
            <div
              className="text-center "
              style={{
                fontSize: "32px",
                fontFamily: "Raleway Bold",
                paddingTop: "20px",
              }}
            >
              <b>If You Have Any Queries, Please Feel Free To Contact Us</b>
            </div>

            <Form onSubmit={this.handleSubmit}>
              <div className="p-3 bg-light border CornersRounded">
                <Row>
                  <Col md={6}>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter Name *"
                      className="mb-3  "
                    >
                      <Form.Control
                        type="text"
                        required
                        placeholder=""
                        className="bg-white"
                        size=""
                        name="customerName"
                        value={this.state.lead.customerName}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid Name.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter Mobile Number *"
                      className="mb-3  "
                    >
                      <Form.Control
                        type="text"
                        required
                        placeholder=""
                        className="bg-white"
                        size=""
                        name="mobileNo"
                        value={this.state.lead.mobileNo}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid Mobile Number.
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter Email id *"
                      className="mb-3  "
                    >
                      <Form.Control
                        type="email"
                        required
                        placeholder=""
                        className="bg-white"
                        size=""
                        name="emailId"
                        value={this.state.lead.emailId}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid Email id.
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label={"Country *"}
                      className="mb-3  "
                    >
                      <Form.Select
                        value={this.state.lead.country}
                        name="country"
                        size=""
                        required
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {Country.getAllCountries().map((country) => (
                          <>
                            <option value={country.isoCode}>
                              {country.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select Country
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label={"State *"}
                      className="mb-3  "
                    >
                      <Form.Select
                        value={this.state.lead.state}
                        name="state"
                        size=""
                        required
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option></option>
                        {State.getStatesOfCountry(this.state.lead.country).map(
                          (state) => (
                            <>
                              <option>{state.name}</option>
                            </>
                          )
                        )}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        Please select State
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter Address"
                      className="mb-3  "
                      height={100}
                    >
                      <Form.Control
                        // as="textarea"
                        // type="textarea"
                        // rows={3}
                        type="text"
                        className="bg-white"
                        name="address"
                        value={this.state.lead.address}
                        autoComplete="off"
                        height={100}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter a valid Address.
                      </Form.Control.Feedback>
                    </FloatingLabel>

                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter City"
                      className="mb-3  "
                    >
                      <Form.Control
                        type="text"
                        placeholder=""
                        className="bg-white"
                        size=""
                        name="city"
                        value={this.state.lead.city}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter City name.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="floatingInput"
                      label="Enter Pincode"
                      className="mb-3  "
                    >
                      <Form.Control
                        type="text"
                        placeholder=""
                        className="bg-white"
                        size=""
                        name="pincode"
                        value={this.state.lead.pincode}
                        onChange={(e) => this.handleInputChange(e)}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter Pincode.
                      </Form.Control.Feedback>
                    </FloatingLabel>
                  </Col>
                  <Col md={6}>
                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Col sm="12">
                        Enter Query / Requirement:
                        <Form.Control
                          as="textarea"
                          type="textarea"
                          rows={22}
                          name="description"
                          maxlength="4999"
                          value={this.state.lead.description}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col className="text-center">
                    <Form.Group>
                      <Button
                        style={{
                          backgroundColor: "#00ff00",
                          color: "black",
                          fontSize: "22px",
                          fontFamily: "Helvetica",
                          // width: "150px",
                        }}
                        type="submit"
                      >
                        Send Message
                      </Button>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Form>
            <br />
            <div class="row pt-2 py-5">
              <div class="col-sm-6">
                <div class="d-flex align-items-center " className="text-center">
                  <MdEmail size="48" color="red" />
                  <div class="ms-3">
                    <h3>
                      <p class="mb-2">Email us</p>
                      <p class="mb-0">
                        {this.context.storeSettings.storeEmailId}
                      </p>
                    </h3>
                  </div>
                </div>
              </div>
              <div class="col-sm-6">
                <div class="d-flex align-items-center" className="text-center">
                  <FaPhone size="48" color="red" />
                  <div class="ms-3">
                    <h3>
                      <p class="mb-2">Call us</p>
                      <p class="mb-0">
                        +{this.context.storeSettings.defaultCountryCode}{" "}
                        {this.context.storeSettings.storePhoneNo}
                      </p>
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <br />
            <iframe
              src={this.context.storeSettings.googlemapEmbedUrl}
              width="100%"
              height="450"
              style={{ border: "1" }}
              allowfullscreen=""
              loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </div>
      </>
    );
  }
}
