import React, { Component } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";

import {
  Container,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Modal,
  Alert,
  ModalBody,
} from "react-bootstrap";

export default class UserLoginNewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  LoginValid = () => {
    this.props.onHide();
  };
  componentDidMount() {}

  render() {
    return (
      <>
        <div className="Container-fluid m-0 p-0 ">
          <Modal
            {...this.props}
            // size="lg"
            aria-labelledby="contained-modal-title-vcenter "
            centered
            backdrop="static"
            keyboard={false}
            className="m-0 p-0"
            dialogClassName="ProductModal  usedLoginDsn"
          >
            <Modal.Header closeButton className="border-0">
              <Modal.Title>
                <h4>
                  {this.context.storeSettings.loginMethod == "SMS"
                    ? "Login/Signup"
                    : "Login/Signup"}
                </h4>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-0 p-0">
              <div className="row mt-0 mx-0 px-0">
                <div className="col-md-12 ">
                  <LoginNewMobile LoginValid={this.LoginValid}></LoginNewMobile>
                </div>
              </div>
            </Modal.Body>
          </Modal>
        </div>
      </>
    );
  }
}

class LoginNewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      mobileno: "",
      emailId: "",
      otpsent: "",
      otpentered: "",
      otpValidationSuccess: false,
      isValidLogin: "false",
      ValidationStatus: "",
      otpValidationStatus: "",
      loginResultText: "",
      userData: null,
      showTermsAndConditions: false,
    };
  }

  handleChangeeMobileNo = (event) => {
    this.setState({ mobileno: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
    this.setState({ otpsent: false });
    this.setState({ otpentered: "" });
  };
  handleEmailIdChange = (event) => {
    this.setState({ emailId: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
    this.setState({ otpsent: false });
    this.setState({ otpentered: "" });
  };

  handleChangeOtp = (event) => {
    this.setState({ otpentered: event.target.value });
    this.setState({ ValidationStatus: "" });
    this.setState({ otpValidationStatus: "" });
    this.setState({ otpValidationSuccess: false });
  };

  validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  SendEmailOtp = (resend) => {
    this.setState({ otpentered: "" });
    if (this.state.emailId.length == "") {
      this.setState({
        ValidationStatus: "Invalid Email ID! Please try again!",
      });
      return;
    }
    var emailvalid = this.validateEmail(this.state.emailId);
    if (emailvalid == false) {
      this.setState({
        ValidationStatus: "Invalid Email ID! Please try again!",
      });
      return;
    }

    var otpValue = Math.floor(1000 + Math.random() * 9000);

    if (this.context.storeSettings.liveMode == false) {
      this.setState({ otpentered: otpValue });
      this.setState({ otpsent: otpValue });
    } else {
      this.setState({ otpsent: otpValue });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        process.env.REACT_APP_API +
          "Consumers/SendEmailOtp/" +
          this.context.storeId +
          "," +
          this.state.emailId +
          "," +
          otpValue,
        requestOptions
      )
        .then((response) => {
          if (response.status === 200) {
            var ctrl = document.getElementById("OTP");
            if (ctrl) ctrl.focus();
            this.setState({
              ValidationStatus:
                "OTP has been sent to your Email Account. \r Check your Inbox or Junk/Spam folders!  ",
            });
            if (resend == true) alert("We have resent OTP! Please check!");
          } else {
            this.setState({
              ValidationStatus: "We Unable to send OTP. Please try later!",
            });
          }
        })
        .catch((error) => {
          console.error("There was an error! ", error);
          alert("err SendSmsOtp:" + error);
        });
    }
  };

  SendSmsOtp = (resend) => {
    this.setState({ otpentered: "" });
    if (this.state.mobileno.length != 10) {
      this.setState({
        ValidationStatus: "Invalid Phone Number! Please try again!",
      });
      return;
    }

    var otpValue = Math.floor(1000 + Math.random() * 9000);

    if (this.context.storeSettings.liveMode == false) {
      this.setState({ otpentered: otpValue });
      this.setState({ otpsent: otpValue });
    } else {
      this.setState({ otpsent: otpValue });
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
      };

      fetch(
        process.env.REACT_APP_API +
          "Consumers/SendAgaramSMS/" +
          "Customer" +
          "," +
          this.state.mobileno +
          "," +
          otpValue,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 404 || data.status == 400) {
            this.setState({
              ValidationStatus: "We Unable to send OTP. Please try later!",
            });
            return;
          }
          var ctrl = document.getElementById("OTP");
          if (ctrl) ctrl.focus();
          this.setState({
            ValidationStatus:
              "Please check!  OTP has been sent to you Phone Number.",
          });
          if (resend == true) alert("We have resent OTP! Please check!");
        })
        .catch((error) => {
          console.error("There was an error!", error);
          alert("err:" + error);
        });
    }
  };

  ValidateOtp = () => {
    if (this.state.otpsent == this.state.otpentered) {
      this.setState({ otpValidationSuccess: true });
      this.setState({ isValidLogin: true });

      this.ValidateLogin();
    } else {
      this.setState({
        otpValidationStatus: "Invalid OTP. Please enter valid OTP!",
      });
    }
  };

  ValidateLogin = () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: this.context.storeId,
        mobileNo: this.state.mobileno,
        emailId: this.state.emailId,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "Consumers/LoginWithPhoneNumber",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404 || data.status == 400) {
          this.setState({
            loginResultText: "Unable to Signup! Pleae try later.",
          });
          return;
        }

        this.setState({ userData: data });
        this.context.storeUserData(data);
        this.props.LoginValid();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("err:" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
  };
  componentDidMount() {}
  componentDidUpdate() {}

  ShowTermsAndConditionsClick = () => {
    this.setState({ showTermsAndConditions: true });
  };
  HideTermsAndConditions = () => {
    this.setState({ showTermsAndConditions: false });
  };

  ShowPrivacyPolicyClick = () => {
    this.setState({ showPrivacyPolicy: true });
  };
  HidePrivacyPolicy = () => {
    this.setState({ showPrivacyPolicy: false });
  };

  render() {
    return (
      <>
        <br></br>
        <div className="mx-0 px-0 p-0 text-dark mt-5 ">
          <Form onSubmit={this.handleSubmit} className="m-0 p-0">
            <div className="text-center m-0 p-0">
              <Row className="m-0 p-0">
                {this.context.storeSettings.loginMethod == "SMS" ? (
                  <>
                    <div className="text-start">Enter your Mobile Number:</div>
                    <Col xs={3} className="m-0 p-0">
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder={
                          "+" + this.context.storeSettings.defaultCountryCode
                        }
                        // className="text-center p-3 w-100 m-0 loginDsn bg-white"
                        className={
                          window.location.hostname == "shop.green.com.pg" ||
                          window.location.hostname == "www.shop.green.com.pg"
                            ? "text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp loginDsn bg-white"
                            : "text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp bg-white primary"
                        }
                        value={
                          "+" + this.context.storeSettings.defaultCountryCode
                        }
                        disabled
                      />
                    </Col>
                    <Col xs={9} className="m-0 p-0">
                      <Form.Control
                        type="text"
                        size="lg"
                        placeholder="Enter your Mobile Number"
                        className={
                          window.location.hostname == "shop.green.com.pg"
                            ? "text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp loginDsn bg-white"
                            : "text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp bg-white primary"
                        }
                        value={this.state.mobileno}
                        disabled={this.state.otpsent != "" ? true : false}
                        maxLength={10}
                        required
                        autoComplete="off"
                        onChange={this.handleChangeeMobileNo}
                      />
                    </Col>
                  </>
                ) : (
                  <>
                    <div className="text-start">Enter your Email ID:</div>
                    <Col xs={12} className="m-0 p-0">
                      <Form.Control
                        size="lg"
                        type="email"
                        placeholder="Enter your Email ID "
                        className="text-center p-3 w-100 m-0 fw-bold signupmobilenoandotp loginDsn"
                        value={this.state.emailId}
                        // disabled={this.state.otpsent != "" ? true : false}
                        required
                        autoComplete="off"
                        onChange={this.handleEmailIdChange}
                      />
                    </Col>
                  </>
                )}
              </Row>
            </div>
            {/* Login Submit */}
            <div className="text-center text-danger">
              <br></br>
              <h5 className="blink_me">{this.state.ValidationStatus}</h5>
            </div>
            <br></br>

            {this.state.otpsent == "" ? (
              <>
                <div className="text-center py-3">
                  <>
                    <Button
                      type="submit"
                      className={
                        window.location.hostname == "shop.green.com.pg" ||
                        window.location.hostname == "www.shop.green.com.pg"
                          ? "w-50 btn-green d-inline-block text-white"
                          : "w-50 "
                      }
                      size="lg"
                      onClick={(e) =>
                        this.context.storeSettings.loginMethod == "SMS"
                          ? this.SendSmsOtp(false)
                          : this.SendEmailOtp(false)
                      }
                    >
                      <b>Send OTP</b>
                    </Button>
                  </>
                </div>
              </>
            ) : (
              ""
            )}

            {this.state.otpsent != "" ? (
              <>
                <div>
                  <Row>
                    <br />
                  </Row>
                  <Row>
                    <Col xs={3}></Col>
                    <Col xs={6}>
                      <Row>
                        <Form.Control
                          size="lg"
                          type="text"
                          maxLength={6}
                          id="OTP"
                          placeholder="Enter OTP"
                          value={this.state.otpentered}
                          className="text-center signupmobilenoandotp"
                          onChange={this.handleChangeOtp}
                        />
                      </Row>
                    </Col>
                    <Col xs={3}></Col>
                  </Row>
                </div>

                {/* Login Submit */}
                <div className="text-center text-danger">
                  <h5 className="blink_me">{this.state.otpValidationStatus}</h5>
                </div>
                <br></br>
                <div className="text-center">
                  {window.location.hostname == "shop.green.com.pg" ? (
                    <>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-50 btn-green d-inline-block text-white"
                        size="lg"
                        onClick={this.ValidateOtp}
                      >
                        <b>Proceed </b>
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        variant="primary"
                        type="submit"
                        className="w-50"
                        style={{
                          backgroundColor:
                            this.context.theme.mainNavBarBackgroundColor,
                          color: this.context.theme.mainNavBarTextColor,
                        }}
                        size="lg"
                        onClick={this.ValidateOtp}
                      >
                        <b>Proceed </b>
                      </Button>
                    </>
                  )}
                </div>
                <br></br>
                <div className="text-center">
                  <Button
                    // as={Link}
                    className=" mx-1 bg-white text-black border-0"
                    // to="\"
                    onClick={(e) =>
                      this.context.storeSettings.loginMethod == "SMS"
                        ? this.SendSmsOtp(true)
                        : this.SendEmailOtp(true)
                    }
                  >
                    <h5>
                      Did not receive OTP?{" "}
                      <span className="text-grn ">
                        <u>Resend OTP</u>
                      </span>
                    </h5>
                  </Button>
                </div>
              </>
            ) : (
              ""
            )}
            <div className="justify-content-center text-secondary border-0 text-center">
              <Row className="text-center">
                <Col>By continuing, you agree to our </Col>
              </Row>
              <Row>
                <Nav className="justify-content-center text-secondary border-0">
                  <Button
                    as={Link}
                    to="/TermsAndConditions"
                    // onClick={this.ShowTermsAndConditionsClick}
                    className="bg-white text-grn border-0"
                  >
                    Terms & Conditions
                  </Button>
                  <Nav.Link as={Link} to="" className="">
                    |
                  </Nav.Link>
                  <Button
                    className="bg-white text-grn border-0"
                    onClick={this.ShowPrivacyPolicyClick}
                  >
                    Privacy Policy
                  </Button>
                </Nav>
              </Row>
            </div>
          </Form>
        </div>
        {this.state.showTermsAndConditions == true ? (
          <TermsAndConditionsModalView
            OnHide={this.HideTermsAndConditions}
          ></TermsAndConditionsModalView>
        ) : (
          ""
        )}
        {this.state.showPrivacyPolicy == true ? (
          <PrivacyPolicyModalView
            OnHide={this.HidePrivacyPolicy}
          ></PrivacyPolicyModalView>
        ) : (
          ""
        )}
      </>
    );
  }
}

export class TermsAndConditionsModalView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { loadSpinnerOpen: false };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  getTermsAndConditions = () => {
    this.ShowLoadSpinner();
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetTermsAndConditionsHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          this.HideLoadSpinner();
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
          this.HideLoadSpinner();
        }
      })
      .catch((error) => {
        console.error("getTermsAndConditions!", error);
        this.HideLoadSpinner();
      });
  };
  handleClose = () => {
    this.props.OnHide();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getTermsAndConditions();
  }
  render() {
    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={this.handleClose}
          dialogClassName="ProductModal"
        >
          <Modal.Header closeButton className="bg-white border">
            <Modal.Title id="contained-modal-title-vcenter">
              Terms and Conditions
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            {this.state.loadSpinnerOpen === true ? (
              <LoadSpinner open="true"></LoadSpinner>
            ) : (
              ""
            )}
            <h5>
              <div id="one"></div>
            </h5>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
export class PrivacyPolicyModalView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { loadSpinnerOpen: false };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  getPrivacyPolicy = () => {
    this.ShowLoadSpinner();
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetPrivacyPolicyHtml/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          this.HideLoadSpinner();
        } else {
          var d1 = document.getElementById("one");
          if (d1) {
            d1.innerHTML = data;
          }
          this.HideLoadSpinner();
        }
      })
      .catch((error) => {
        console.error("getPrivacyPolicy!", error);
        this.HideLoadSpinner();
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getPrivacyPolicy();
  }

  handleClose = () => {
    this.props.OnHide();
  };

  render() {
    return (
      <div className="container">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          onHide={this.handleClose}
          dialogClassName="ProductModal"
        >
          <Modal.Header closeButton className="bg-white border">
            <Modal.Title id="contained-modal-title-vcenter">
              Privacy Policy
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            {this.state.loadSpinnerOpen === true ? (
              <LoadSpinner open="true"></LoadSpinner>
            ) : (
              ""
            )}
            <h5>
              <div id="one"></div>
            </h5>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
