import React, { useState, useContext, Component, createRef } from "react";
import pincode from "pincode-distance/lib/pincode";
import { Country, State, City } from "country-state-city";
import CartContext from "./CartContext";
import "../CSS/3d.css";
import { Navigate } from "react-router-dom";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { sha256 } from "js-sha256";
import { OfflineStore } from "./OfflineStore";
import IncDecCounter from "./IncDecCounterControl";
import { BlobServiceClient } from "@azure/storage-blob";

import {
  Modal,
  Nav,
  Form,
  FloatingLabel,
  Table,
  Container,
  Row,
  Col,
  Button,
  Stack,
} from "react-bootstrap";

import CheckoutAndPayForm from "./CheckoutAndPayForm";

export default class PayView extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
      customerName: "",
      emailId: "",
      address: "",
      country: "",
      state: "",
      city: "",
      pincode: "",

      quoteRequest: [],
      selectedQuoteNumber: "",
      payingFull: false,
      payingPartial: false,

      totalAmountToPay: 0,
      totalPaidAmount: 0,
      payingAmount: 0,
      razorpayStatus: "",
    };
    this.inputFile = React.createRef();
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      await this.uploadFileToBlob(files[i]);
    }
    console.log("All uploaded ");
    this.HideLoadSpinner();
  };
  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    var targetFileName =
      file.name.split(".")[0] +
      new Date().toDateString() +
      "." +
      file.name.split(".")[1];

    const blobClient = containerClient.getBlockBlobClient(
      "attachments/" + targetFileName
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    this.UpdateAttachmentFile(targetFileName);
  };

  UpdateAttachmentFile = (attachmentFileName) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateQuoteAttachmentFile/" +
          attachmentFileName +
          "/" +
          this.context.storeId +
          "/" +
          this.state.selectedQuoteNumber
      )
      .then((response) => {
        if (response.status === 200) {
          var r = this.state.quoteRequest.filter(
            (f) => f.quoteNumber == this.state.selectedQuoteNumber
          );
          if (r.length > 0) {
            r[0].attachmentFileName = attachmentFileName;
            this.setState({ quoteRequest: this.state.quoteRequest });
          }
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Failed!",
          confirmButtonColor: "#23B14D",
          text: "Submit failed. Please try after sometime!",
          confirmButtonText: "Ok",
        }).then((result) => {});
      });
  };

  ValidateBeforePay = () => {
    if (
      this.state.payingPartial == true &&
      Number(this.state.payingAmount) > 0 != true
    ) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text: "Enter the amount and proceed!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }

    if (this.state.payingAmount > this.GetTotalAmountToPay()) {
      Swal.fire({
        title: "Information!",
        confirmButtonColor: "#23B14D",
        text:
          "Due amount is only " +
          this.GetTotalAmountToPay().toLocaleString(
            this.context.storeSettings.defaultLocale,
            {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
              style: "currency",
              currency: this.context.storeSettings.defaultCurrency,
            }
          ),
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      return false;
    }
    return true;
  };

  CallRazorPay = () => {
    if (this.ValidateBeforePay() == false) return;

    var ordid = "Order_" + new Date().getTime();
    var custName =
      this.context.userData.firstName + " " + this.context.userData.lastName;
    var emailId = this.context.userData.emailId;
    var mobileNo = this.context.userData.mobileNo;

    var options = {
      key: this.context.storeSettings.razorpayKey,
      key_secret: this.context.storeSettings.razorpayKeySecret,
      amount: this.state.payingAmount * 100,
      currency: this.context.storeSettings.razorpayCurrency,
      name: this.context.storeSettings.razorpayCompanyName,
      description: this.context.storeSettings.razorpayCompanyDescription,
      orderid: this.state.selectedQuoteNumber,
      handler: (response) => {
        this.SuccessRazorPayResponse(response);
      },
      prefill: {
        name: custName, //customer name
        email: emailId, //customer emailId
        contact: mobileNo, //customer phoneno
      },
      notes: {
        address: this.context.storeSettings.razorpayCompanyAddress,
      },
      theme: {
        color: this.context.storeSettings.razorpayThemeColor,
      },
      modal: {
        escape: false,
        ondismiss: () => {
          this.setState({ razorpayStatus: "Dismissed" });
        },
      },
    };
    var pay = window.Razorpay(options);

    pay.on("payment.failed", (response) => {
      this.FailedInRazorPayResponse(response);
    });
    pay.open();
  };

  FailedInRazorPayResponse = (response) => {
    this.setState({ razorpayStatus: "Failed" });
  };

  SuccessRazorPayResponse = (response) => {
    console.log(response.razorpay_payment_id);
    this.SubmitQuotePayment(response.razorpay_payment_id);
  };

  SubmitQuotePayment = (paymentTransactionId) => {
    console.log(paymentTransactionId);

    var order = this.state.quoteRequest.filter(
      (f) => f.quoteNumber == this.state.selectedQuoteNumber
    )[0];

    var str = {
      quoteId: order.id,
      paymentTransactionId: paymentTransactionId,
      amount: this.state.payingAmount,
    };
    axios
      .post(process.env.REACT_APP_API + "Consumers/SubmitQuotePayment", str)
      .then((response) => {
        if (response.status === 200) {
          console.log("Success");
          this.setState({ razorpayStatus: "Success" });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  SaveDeliveryAddress = (e, order) => {
    order.customerName = this.state.customerName;
    order.emailId = this.state.emailId;
    order.country = this.state.country;
    order.state = this.state.state;
    order.address = this.state.address;
    order.city = this.state.city;
    order.pincode = this.state.pincode;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(order),
    };
    fetch(
      process.env.REACT_APP_API + "Consumers/UpdateQuationRequest/",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        Swal.fire({
          title: "Status",
          text: "Saved Successfully",
          timer: 1000,
        }).then((result) => {});
        // this.ShowMessagBox("Status", "Saved Successfully!");
      })
      .catch((error) => {
        //this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };

  GetQuoteRequestsByUserID = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "Consumers/GetQuoteRequestsByUserID/" +
          this.context.storeId +
          "/" +
          this.context.userData.mobileNo
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ quoteRequest: response.data });
        }
      })
      .catch((error) => {
        console.error("There was an error! ", error);
      });
  };

  GetTotalAmountToPay = () => {
    var result = this.state.quoteRequest.filter(
      (f) => f.quoteNumber == this.state.selectedQuoteNumber
    );
    if (result.length > 0) {
      return (
        result[0].quoteItems.reduce(
          (a, v) => (a = a + Number(v.quoteAmount)),
          0
        ) +
        (result[0].quoteItems.reduce(
          (a, v) => (a = a + Number(v.quoteAmount)),
          0
        ) *
          this.context.taxDetails.taxPercentage) /
          100 -
        this.GetTotalAmountReceived()
      );
    }
    return 0;
  };
  GetTotalAmountReceived = () => {
    var result = this.state.quoteRequest.filter(
      (f) => f.quoteNumber == this.state.selectedQuoteNumber
    );
    if (result.length > 0) {
      return result[0].quotePayments.reduce(
        (a, v) => (a = a + Number(v.amount)),
        0
      );
    }
    return 0;
  };
  handleSelectQuoteNumber = (e) => {
    const { name, value } = e.target;
    this.setState({ selectedQuoteNumber: value }, () => {
      var result = this.state.quoteRequest.filter(
        (f) => f.quoteNumber == value
      );
      if (result.length > 0) {
        this.setState({ customerName: result[0].customerName });
        this.setState({ emailId: result[0].emailId });
        this.setState({ address: result[0].address });
        this.setState({ country: result[0].country });
        this.setState({ city: result[0].city });
        this.setState({ state: result[0].state });
        this.setState({ pincode: result[0].pincode });

        this.setState({
          totalAmountToPay: this.GetTotalAmountToPay(),
        });

        this.setState({ payingFull: false });
        this.setState({ payingPartial: false });
        this.setState({
          totalPaidAmount: this.GetTotalAmountReceived(),
        });
        this.setState({ payingAmount: 0 });
        this.setState({ razorpayStatus: "" });
      }
    });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.GetQuoteRequestsByUserID();
    this.setState({
      Name:
        this.context.userData.firstName == null
          ? ""
          : this.context.userData.firstName +
              " " +
              this.context.userData.lastName ==
            null
          ? ""
          : this.context.userData.lastName,
    });
    this.setState({ EmailId: this.context.userData.emailId });
  }

  GetQuoteStatusName = (id) => {
    if (id == 1 || id == 2) {
      return "We are Reviewing";
    } else if (id == 3 || id == 4 || id == 5) {
      return "Quotation Sent and pay the outstanding";
    } else if (id == 6) {
      return "Product is under produce";
    } else if (id == 7) {
      return "Product is ready to ship";
    } else if (id == 8) {
      return "Product shipped";
    } else if (id == 9) {
      return "Delivered";
    } else if (id == 10) {
      return "Cancelled";
    }

    // var result = this.context.quotationTypes.filter((f) => f.id == id);
    // if (result.length > 0)
    //   {

    //     return result[0].statusName;
    //   }
    return "";
  };

  DeleteBlobFile = async (order) => {
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );
    const options = {
      deleteSnapshots: "include", // or 'only'
    };

    const blockBlobClient = containerClient.getBlockBlobClient(
      "attachments/" + order.attachmentFileName
    );

    await blockBlobClient.deleteIfExists(options);

    var result = this.state.quoteRequest.filter(
      (f) => f.quoteNumber == this.state.selectedQuoteNumber
    );

    result[0].attachmentFileName = "";
    this.setState({ quoteRequest: this.state.quoteRequest });

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(result[0]),
    };
    fetch(
      process.env.REACT_APP_API + "Consumers/UpdateQuationRequest/",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          timer: 1500,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };

  componentDidUpdate = () => {
    if (this.context.refreshIncDecControl == true) {
      this.context.SetRefreshIncDecControl(false);
      this.setState({ reload: true }, () => {
        this.setState({ reload: false });
      });
    }
  };
  refresh = () => {};
  render() {
    if (this.context.storeId == "") return <Navigate to="/home" />;
    if (this.state.razorpayStatus == "Success") return <Navigate to="/home" />;

    return (
      <>
        <Helmet>
          <link href="dist/css/bootstrap.min.css" rel="stylesheet" />
          <link href="dist/css/grnstyle.css" rel="stylesheet" />
          {/* <link href="dist/css/grnresponsive.css" rel="stylesheet" /> */}
          <link href="dist/css/font-awesome.min.css" rel="stylesheet" />
          {/* <script src="dist/js/grncustom.js"></script> */}
          <link href="dist/css/style.css" rel="stylesheet" />
        </Helmet>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <input
          style={{ display: "none" }}
          accept=".png,.jpg,.jpeg"
          ref={this.inputFile}
          onChange={this.handleFileUpload}
          type="file"
          multiple="multiple"
        />
        {window.innerWidth > 1200 ? (
          <div className="Container-fluid mx-0 px-0 checkout-container">
            <div className="row mt-1 mx-0 px-0 inner-row">
              <>
                <Row>
                  <Col xs={5} md={1} className="">
                    <div class="">
                      <h2 className=" ectittle-black-outline-5">Checkout </h2>
                    </div>
                  </Col>
                  <Col xs={5} md={10} className="">
                    <br />
                    <br />
                    <br />

                    <div className="px-1 w-100 border-0">
                      <div
                        className="m-0 p-0 w-100 border-0 text-center"
                        // style={{ backgroundColor: "darkblue", color: "white" }}
                        style={{
                          fontSize: "18px",
                          letterSpacing: "5px",
                          fontFamily: "Raleway Bold",
                        }}
                      >
                        <h1>
                          <b>My Orders and Checkout</b>
                        </h1>
                      </div>
                      <hr />
                      <h4>
                        <Row>
                          <Col></Col>
                          <Col>Select your Quotation Number:</Col>
                          <Col>
                            <Form.Select
                              name="selectedQuoteNumber"
                              type="text"
                              size="lg"
                              value={this.state.quoteNumber}
                              onChange={(e) => this.handleSelectQuoteNumber(e)}
                            >
                              <option value=""></option>
                              {this.state.quoteRequest
                                .sort((a, b) =>
                                  a.created > b.created ? 1 : -1
                                )
                                .map((m) => (
                                  <option value={m.quoteNumber}>
                                    {m.quoteNumber} - [Created on:&nbsp;
                                    {this.context.GetInDateFormat(
                                      new Date(m.created)
                                    )}
                                    ]
                                  </option>
                                ))}
                            </Form.Select>
                          </Col>
                          <Col></Col>
                        </Row>
                      </h4>

                      <Form style={{ fontFamily: "Helvetica" }}>
                        {this.state.quoteRequest
                          .filter(
                            (f) =>
                              f.quoteNumber == this.state.selectedQuoteNumber
                          )
                          .map((order, i) => (
                            <>
                              <br />
                              <span
                                style={{
                                  fontSize: "24px",
                                  // letterSpacing: "3px",
                                  fontFamily: "Raleway bold",
                                }}
                              >
                                Order Summary{" "}
                              </span>
                              <Row className="mx-0 my-2 border ">
                                <Col sm={6} className="border p-3">
                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formPlaintextEmail"
                                  >
                                    <Form.Label column sm="5">
                                      Order Date:
                                    </Form.Label>
                                    <Col sm="7">
                                      <Form.Control
                                        type="text"
                                        // plaintext
                                        readOnly
                                        value={this.context.GetInDateFormat(
                                          new Date(order.created)
                                        )}
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-3 py-2"
                                    controlId="formPlaintextEmail"
                                    style={{ backgroundColor: "lightyellow" }}
                                  >
                                    <Form.Label column sm="5">
                                      <h3>Current Status:</h3>
                                    </Form.Label>
                                    <Col sm="7">
                                      <Form.Control
                                        type="text"
                                        // plaintext
                                        style={{ fontSize: "18px" }}
                                        readOnly
                                        value={this.GetQuoteStatusName(
                                          order.quoteStatus
                                        )}
                                      />
                                    </Col>
                                  </Form.Group>

                                  <Form.Group
                                    as={Row}
                                    className="mb-3"
                                    controlId="formPlaintextEmail"
                                  >
                                    <Form.Label column sm="5">
                                      Attachment File :&nbsp;
                                    </Form.Label>
                                    <Col sm="7">
                                      {order.attachmentFileName != undefined &&
                                      order.attachmentFileName != "" ? (
                                        <>
                                          <Form.Label>
                                            <div className="border CornersRoundedSmall p-1">
                                              {order.attachmentFileName}{" "}
                                              &nbsp;&nbsp;&nbsp;
                                              <a
                                                href={
                                                  this.context.store
                                                    .storageBlobUrl +
                                                  this.context.store
                                                    .storageBlobContainerName +
                                                  "/attachments/" +
                                                  order.attachmentFileName +
                                                  this.context.store
                                                    .storageSasToken
                                                }
                                                target="_blank"
                                                className="text-green text-decoration-none"
                                              >
                                                View
                                              </a>
                                              &nbsp;&nbsp;&nbsp;
                                              <Button
                                                className="bg-green"
                                                onClick={(e) => {
                                                  Swal.fire({
                                                    title: "Warning!",
                                                    confirmButtonColor:
                                                      "#23B14D",
                                                    cancelButtonColor:
                                                      "#dbdbdb",
                                                    text: "Do you want to delete?",
                                                    showCancelButton: "true",
                                                    confirmButtonText: "Yes",
                                                    cancelButtonText: "No",
                                                  }).then((result) => {
                                                    if (result.isConfirmed) {
                                                      this.DeleteBlobFile(
                                                        order
                                                      );
                                                    }
                                                  });
                                                }}
                                              >
                                                X
                                              </Button>
                                            </div>
                                          </Form.Label>
                                        </>
                                      ) : (
                                        <>
                                          <Link
                                            className="text-green text-decoration-none"
                                            onClick={(e) => {
                                              // order.attachmentFileName =
                                              this.inputFile.current.click();
                                              // this.setState({ quote: order });
                                            }}
                                          >
                                            click to attach{" "}
                                          </Link>
                                        </>
                                      )}
                                    </Col>
                                  </Form.Group>
                                </Col>

                                <Col sm={6} className="border p-3">
                                  {this.GetTotalAmountToPay() > 0 ? (
                                    <>
                                      {(order.quoteStatus == 3 ||
                                        order.quoteStatus == 5 ||
                                        order.quoteStatus == 6 ||
                                        order.quoteStatus == 7) &&
                                      order.paymentStatus != 1 ? (
                                        <>
                                          <Form.Group
                                            as={Row}
                                            className="mb-3"
                                            controlId="formPlaintextEmail"
                                          >
                                            <Form.Label column sm="5">
                                              Payment due :
                                            </Form.Label>
                                            <Col sm="7">
                                              <Form.Control
                                                type="text"
                                                plaintext
                                                readOnly
                                                value={this.GetTotalAmountToPay().toLocaleString(
                                                  this.context.storeSettings
                                                    .defaultLocale,
                                                  {
                                                    minimumFractionDigits: 0,
                                                    maximumFractionDigits: 0,
                                                    style: "currency",
                                                    currency:
                                                      this.context.storeSettings
                                                        .defaultCurrency,
                                                  }
                                                )}
                                              />
                                            </Col>
                                          </Form.Group>
                                          <hr />
                                          <Form.Group
                                            as={Row}
                                            className="mb-3  px-3 my-2"
                                            controlId="formPlaintextEmail"
                                          >
                                            <Form.Label column sm="5">
                                              Paying now
                                            </Form.Label>
                                            <Col sm="5">
                                              <Form.Check
                                                type="radio"
                                                size="lg"
                                                name="paying"
                                                id="payingfull"
                                                checked={this.state.payingFull}
                                                label={
                                                  "Paying " +
                                                  this.GetTotalAmountToPay().toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )
                                                }
                                                onChange={(e) => {
                                                  this.setState({
                                                    payingFull: true,
                                                  });
                                                  this.setState({
                                                    payingPartial: false,
                                                  });
                                                  this.setState({
                                                    payingAmount:
                                                      this.GetTotalAmountToPay(),
                                                  });
                                                }}
                                              />
                                              <br />
                                              <Form.Check
                                                type="radio"
                                                name="paying"
                                                size="lg"
                                                id="payingpartial"
                                                checked={
                                                  this.state.payingPartial
                                                }
                                                label="Paying partial amount"
                                                onChange={(e) => {
                                                  this.setState({
                                                    payingPartial: true,
                                                  });
                                                  this.setState({
                                                    payingFull: false,
                                                  });
                                                }}
                                              />
                                              <Form.Group
                                                as={Row}
                                                className="mb-3"
                                                controlId="formPlaintextEmail"
                                              >
                                                <Form.Label column sm="5">
                                                  Enter Amount:
                                                </Form.Label>
                                                <Col sm="7">
                                                  <Form.Control
                                                    size="lg"
                                                    type="text"
                                                    required
                                                    disabled={
                                                      this.state
                                                        .payingPartial == false
                                                    }
                                                    onChange={(e) => {
                                                      this.setState({
                                                        payingAmount:
                                                          e.target.value,
                                                      });
                                                    }}
                                                  />
                                                </Col>
                                              </Form.Group>
                                            </Col>
                                          </Form.Group>
                                          <hr />
                                          <div className="text-center">
                                            <Button
                                              className="mx-1"
                                              size="lg"
                                              disabled={
                                                this.state.payingPartial ==
                                                  false &&
                                                this.state.payingFull == false
                                              }
                                              style={{
                                                // backgroundColor: "#00ff00",
                                                backgroundColor: "#EA871E",
                                                color: "white",
                                                fontSize: "18px",
                                                fontFamily: "Helvetica",
                                                width: "150px",
                                              }}
                                              onClick={(e) => {
                                                this.CallRazorPay();
                                              }}
                                            >
                                              Pay
                                            </Button>
                                          </div>
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  ) : (
                                    <></>
                                  )}
                                </Col>
                              </Row>

                              <div className="text-start ">
                                <span
                                  style={{
                                    fontSize: "24px",
                                    // letterSpacing: "3px",
                                    fontFamily: "Raleway bold",
                                  }}
                                >
                                  Order Details{" "}
                                </span>
                              </div>
                              <div className="p-3 bg-light CornersRounded">
                                <Table
                                  className="text-center"
                                  style={{
                                    fontSize: "14px",
                                    // letterSpacing: "3px",
                                    fontFamily: "Raleway",
                                  }}
                                >
                                  <thead className="bg-light">
                                    <th width="5%">#</th>
                                    <th width="15%" className="text-start">
                                      Category
                                    </th>
                                    <th width="15%" className="text-start">
                                      Product Name
                                    </th>
                                    <th width="10%">Quantity</th>
                                    <th width="30%" className="text-start">
                                      Detail Requirement
                                    </th>
                                    <th width="10%" className="text-end">
                                      Quote Amount (INR)
                                    </th>
                                  </thead>
                                  <tbody>
                                    {order.quoteItems
                                      .sort((a, b) =>
                                        a.seqNo > b.seqNo ? 1 : -1
                                      )
                                      .map((item, i) => (
                                        <>
                                          <tr>
                                            <td> {i + 1}</td>
                                            <td className="text-start">
                                              {item.categoryName}
                                            </td>
                                            <td className="text-start">
                                              {item.productName}
                                            </td>
                                            <td>{item.qty}</td>
                                            <td className="text-start">
                                              {item.detailedRequirement}
                                            </td>
                                            <td className="text-end">
                                              <h5>
                                                <b>
                                                  {Number(
                                                    item.quoteAmount
                                                  ).toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )}
                                                </b>
                                              </h5>
                                            </td>
                                          </tr>
                                        </>
                                      ))}
                                    <tr className="bg-white">
                                      <td colSpan={5} className="text-end">
                                        <h5>
                                          Tax(
                                          {
                                            this.context.taxDetails
                                              .taxPercentage
                                          }
                                          %):
                                        </h5>
                                      </td>
                                      <td className="text-end">
                                        <h5>
                                          {(
                                            (order.quoteItems.reduce(
                                              (a, v) =>
                                                (a = a + Number(v.quoteAmount)),
                                              0
                                            ) *
                                              this.context.taxDetails
                                                .taxPercentage) /
                                            100
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </h5>
                                      </td>
                                    </tr>
                                    <tr className="bg-white">
                                      <td colSpan={5} className="text-end">
                                        <h3>Total:</h3>
                                      </td>
                                      <td className="text-end">
                                        <h3>
                                          {(
                                            Number(
                                              order.quoteItems.reduce(
                                                (a, v) =>
                                                  (a =
                                                    a + Number(v.quoteAmount)),
                                                0
                                              )
                                            ) +
                                            Number(
                                              (order.quoteItems.reduce(
                                                (a, v) =>
                                                  (a =
                                                    a + Number(v.quoteAmount)),
                                                0
                                              ) *
                                                this.context.taxDetails
                                                  .taxPercentage) /
                                                100
                                            )
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </h3>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </div>

                              {order.quotePayments &&
                              order.quotePayments.length > 0 ? (
                                <>
                                  <br />
                                  <Row>
                                    <Col>
                                      <span
                                        style={{
                                          fontSize: "24px",
                                          // letterSpacing: "3px",
                                          fontFamily: "Raleway bold",
                                        }}
                                      >
                                        Payment History
                                      </span>
                                      <Table
                                        className="bg-light CornersRounded"
                                        style={{
                                          fontSize: "14px",
                                          // letterSpacing: "3px",
                                          fontFamily: "Raleway",
                                        }}
                                      >
                                        <thead>
                                          <th>Received</th>
                                          <th>TransactionID</th>
                                          <th>Amount</th>
                                        </thead>
                                        <tbody>
                                          {order.quotePayments.map(
                                            (payment) => (
                                              <tr>
                                                <td>
                                                  {this.context.GetInDateFormat(
                                                    new Date(payment.created)
                                                  )}
                                                </td>
                                                <td>
                                                  {payment.paymentTransactionId}
                                                </td>
                                                <td>
                                                  {Number(
                                                    payment.amount
                                                  ).toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                          <tr>
                                            <td></td>
                                            <td>
                                              <b>Total Paid:</b>
                                            </td>
                                            <td>
                                              <b>
                                                {order.quotePayments
                                                  .reduce(
                                                    (a, v) =>
                                                      (a =
                                                        a + Number(v.amount)),
                                                    0
                                                  )
                                                  .toLocaleString(
                                                    this.context.storeSettings
                                                      .defaultLocale,
                                                    {
                                                      minimumFractionDigits: 0,
                                                      maximumFractionDigits: 0,
                                                      style: "currency",
                                                      currency:
                                                        this.context
                                                          .storeSettings
                                                          .defaultCurrency,
                                                    }
                                                  )}
                                              </b>
                                            </td>
                                          </tr>
                                        </tbody>
                                      </Table>
                                    </Col>
                                  </Row>
                                </>
                              ) : (
                                <></>
                              )}
                              <span
                                style={{
                                  fontSize: "24px",
                                  // letterSpacing: "3px",
                                  fontFamily: "Raleway bold",
                                }}
                              >
                                Delivery Address{" "}
                              </span>
                              <Row className="mx-0 my-2 bg-light p-3 CornersRounded">
                                <Col>
                                  <Row>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Name *"
                                        className="mb-3  "
                                      >
                                        <Form.Control
                                          type="text"
                                          name="customerName"
                                          placeholder="First Name *"
                                          className="bg-white"
                                          required
                                          value={this.state.customerName}
                                          onChange={(e) => {
                                            this.setState({
                                              customerName: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please enter a name
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Mobile Number*"
                                        className="mb-3  "
                                      >
                                        <Form.Control
                                          type="text"
                                          name="contactNumber"
                                          placeholder="Mobile No *"
                                          className="bg-white"
                                          readOnly
                                          value={order.contactNumber}
                                          onChange={(e) => {
                                            this.setState({
                                              contactNumber: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please enter a valid{" "}
                                          {
                                            this.context.storeSettings
                                              .phoneNumberLength
                                          }
                                          digit Phone Number!
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Email ID *"
                                        className="mb-3  "
                                      >
                                        <Form.Control
                                          type="email"
                                          name="emailId"
                                          placeholder="Email address *"
                                          required
                                          className="bg-white"
                                          value={this.state.emailId}
                                          onChange={(e) => {
                                            this.setState({
                                              emailId: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please enter a valid Email ID.
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <Form.Control
                                        as="textarea"
                                        name="address"
                                        rows={2}
                                        required
                                        placeholder="Enter Address *"
                                        value={this.state.address}
                                        onChange={(e) => {
                                          this.setState({
                                            address: e.target.value,
                                          });
                                        }}
                                      />
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Country *"
                                        className="mb-3  "
                                      >
                                        <Form.Select
                                          value={this.state.country}
                                          name="country"
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              country: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value=""></option>
                                          {Country.getAllCountries().map(
                                            (country) => (
                                              <>
                                                <option value={country.isoCode}>
                                                  {country.name}
                                                </option>
                                              </>
                                            )
                                          )}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          Please select country
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label={
                                          window.location.hostname ==
                                          "shop.green.com.pg" ? (
                                            <>Province *</>
                                          ) : (
                                            <>State *</>
                                          )
                                        }
                                        className="mb-3  "
                                      >
                                        <Form.Select
                                          value={this.state.state}
                                          name="state"
                                          required
                                          onChange={(e) => {
                                            this.setState({
                                              state: e.target.value,
                                            });
                                          }}
                                        >
                                          <option value=""></option>
                                          {State.getStatesOfCountry(
                                            this.state.country
                                          ).map((s1) => (
                                            <>
                                              <option>{s1.name}</option>
                                            </>
                                          ))}
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                          Please select State
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="City *"
                                        className="mb-3  "
                                      >
                                        <Form.Control
                                          type="text"
                                          name="city"
                                          placeholder="City *"
                                          required
                                          className="bg-white"
                                          value={this.state.city}
                                          onChange={(e) => {
                                            this.setState({
                                              city: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please Enter City
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                    <Col>
                                      <FloatingLabel
                                        controlId="floatingInput"
                                        label="Postal Code *"
                                        className="mb-3  "
                                      >
                                        <Form.Control
                                          type="number"
                                          name="pincode"
                                          placeholder="Postal Code *"
                                          className="bg-white"
                                          required
                                          value={this.state.pincode}
                                          onChange={(e) => {
                                            this.setState({
                                              pincode: e.target.value,
                                            });
                                          }}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                          Please enter valid{" "}
                                          {
                                            this.context.storeSettings
                                              .pincodeLength
                                          }{" "}
                                          digit Postal Code!
                                        </Form.Control.Feedback>
                                      </FloatingLabel>
                                    </Col>
                                  </Row>
                                  <Row>
                                    <Col className="text-center">
                                      <Button
                                        className=" "
                                        style={{
                                          backgroundColor: "#00ff00",
                                          color: "white",
                                          fontSize: "14px",
                                          fontFamily: "Helvetica",
                                          width: "250px",
                                        }}
                                        onClick={(e) =>
                                          this.SaveDeliveryAddress(e, order)
                                        }
                                      >
                                        Save Delivery Address
                                      </Button>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </>
                          ))}
                      </Form>
                    </div>
                  </Col>
                  <Col xs={5} md={1} className=""></Col>
                </Row>
              </>
            </div>
          </div>
        ) : (
          <>
            <br />
            <br />
            <div className="Container-fluid  px-0 w-100">
              <div className="my-5 px-1 w-100 border-0">
                <div
                  className="m-0 p-0 w-100 border-0 text-center bg-light"
                  // style={{ backgroundColor: "darkblue", color: "white" }}
                  style={{
                    fontSize: "24px",
                    letterSpacing: "5px",
                    fontFamily: "Raleway Bold",
                  }}
                >
                  <b>My Orders and Checkout</b>
                </div>
                {/* <hr /> */}
                <br />
                <h4>
                  Select your Quotation Number:
                  <Form.Select
                    name="selectedQuoteNumber"
                    type="text"
                    size="lg"
                    value={this.state.quoteNumber}
                    onChange={(e) => this.handleSelectQuoteNumber(e)}
                  >
                    <option value=""></option>
                    {this.state.quoteRequest
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((m) => (
                        <option value={m.quoteNumber}>
                          {m.quoteNumber} - [Created on:&nbsp;
                          {this.context.GetInDateFormat(new Date(m.created))}]
                        </option>
                      ))}
                  </Form.Select>
                </h4>

                <Form style={{ fontFamily: "Helvetica" }}>
                  {this.state.quoteRequest
                    .filter(
                      (f) => f.quoteNumber == this.state.selectedQuoteNumber
                    )
                    .map((order, i) => (
                      <>
                        <Row className="mx-1 my-2">
                          <Col sm={12} className="border CornersRounded p-3">
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextEmail"
                            >
                              <Form.Label column sm="5">
                                <b>Order Date:</b>
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control
                                  type="text"
                                  // plaintext
                                  readOnly
                                  value={this.context.GetInDateFormat(
                                    new Date(order.created)
                                  )}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextEmail"
                              style={{ backgroundColor: "lightyellow" }}
                            >
                              <Form.Label column sm="5">
                                <h3>Current Status:</h3>
                              </Form.Label>
                              <Col sm="7">
                                <Form.Control
                                  type="text"
                                  style={{ fontSize: "18px" }}
                                  readOnly
                                  value={this.GetQuoteStatusName(
                                    order.quoteStatus
                                  )}
                                />
                              </Col>
                            </Form.Group>

                            <Form.Group
                              as={Row}
                              className="mb-3"
                              controlId="formPlaintextEmail"
                            >
                              <Form.Label column sm="5">
                                <b>Attachment File :</b>&nbsp;
                              </Form.Label>
                              <Col sm="7">
                                {order.attachmentFileName != undefined &&
                                order.attachmentFileName != "" ? (
                                  <>
                                    <Form.Label>
                                      <div className="border CornersRoundedSmall p-1">
                                        {order.attachmentFileName}
                                        &nbsp;&nbsp;&nbsp;
                                        <a
                                          href={
                                            this.context.store.storageBlobUrl +
                                            this.context.store
                                              .storageBlobContainerName +
                                            "/attachments/" +
                                            order.attachmentFileName +
                                            this.context.store.storageSasToken
                                          }
                                          target="_blank"
                                          className="text-green text-decoration-none"
                                        >
                                          View
                                        </a>
                                        &nbsp;&nbsp;&nbsp;
                                        <Button
                                          className="bg-green"
                                          onClick={(e) => {
                                            Swal.fire({
                                              title: "Warning!",
                                              confirmButtonColor: "#23B14D",
                                              cancelButtonColor: "#dbdbdb",
                                              text: "Do you want to delete?",
                                              showCancelButton: "true",
                                              confirmButtonText: "Yes",
                                              cancelButtonText: "No",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                this.DeleteBlobFile(order);
                                              }
                                            });
                                          }}
                                        >
                                          X
                                        </Button>
                                      </div>
                                    </Form.Label>
                                  </>
                                ) : (
                                  <>
                                    <Link
                                      className="text-green text-decoration-none"
                                      onClick={(e) => {
                                        // order.attachmentFileName =
                                        this.inputFile.current.click();
                                        // this.setState({ quote: order });
                                      }}
                                    >
                                      click to attach{" "}
                                    </Link>
                                  </>
                                )}
                              </Col>
                            </Form.Group>

                            {this.GetTotalAmountToPay() > 0 ? (
                              <>
                                {(order.quoteStatus == 3 ||
                                  order.quoteStatus == 5 ||
                                  order.quoteStatus == 6 ||
                                  order.quoteStatus == 7) &&
                                order.paymentStatus != 1 ? (
                                  <>
                                    <Form.Group
                                      as={Row}
                                      className="mb-3"
                                      controlId="formPlaintextEmail"
                                    >
                                      <Form.Label column sm="5">
                                        <b>Payment due :</b>
                                      </Form.Label>
                                      <Col sm="7">
                                        <Form.Control
                                          type="text"
                                          readOnly
                                          value={this.GetTotalAmountToPay().toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        />
                                      </Col>
                                    </Form.Group>
                                    <hr />
                                    <Form.Group
                                      as={Row}
                                      className="mb-3  px-3 my-2"
                                      controlId="formPlaintextEmail"
                                    >
                                      <Form.Label column sm="5">
                                        <b>Paying now</b>
                                      </Form.Label>
                                      <Col
                                        sm="5"
                                        className="bg-light CornersRounded p-2"
                                      >
                                        <Form.Check
                                          type="radio"
                                          size="lg"
                                          name="paying"
                                          id="payingfull"
                                          checked={this.state.payingFull}
                                          label={
                                            "Paying " +
                                            this.GetTotalAmountToPay().toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )
                                          }
                                          onChange={(e) => {
                                            this.setState({
                                              payingFull: true,
                                            });
                                            this.setState({
                                              payingPartial: false,
                                            });
                                            this.setState({
                                              payingAmount:
                                                this.GetTotalAmountToPay(),
                                            });
                                          }}
                                        />
                                        <br />
                                        <Form.Check
                                          type="radio"
                                          name="paying"
                                          size="lg"
                                          id="payingpartial"
                                          checked={this.state.payingPartial}
                                          label="Paying partial amount"
                                          onChange={(e) => {
                                            this.setState({
                                              payingPartial: true,
                                            });
                                            this.setState({
                                              payingFull: false,
                                            });
                                          }}
                                        />
                                        <Form.Group
                                          as={Row}
                                          className="mb-3"
                                          controlId="formPlaintextEmail"
                                        >
                                          <Form.Label column sm="5">
                                            Enter Amount:
                                          </Form.Label>
                                          <Col sm="7">
                                            <Form.Control
                                              size="lg"
                                              type="text"
                                              required
                                              disabled={
                                                this.state.payingPartial ==
                                                false
                                              }
                                              onChange={(e) => {
                                                this.setState({
                                                  payingAmount: e.target.value,
                                                });
                                              }}
                                            />
                                          </Col>
                                        </Form.Group>
                                      </Col>
                                    </Form.Group>
                                    <hr />
                                    <div className="text-center">
                                      <Button
                                        className="mx-1"
                                        size="lg"
                                        disabled={
                                          this.state.payingPartial == false &&
                                          this.state.payingFull == false
                                        }
                                        style={{
                                          backgroundColor: "#EA871E",
                                          color: "black",
                                          fontSize: "18px",
                                          fontFamily: "Helvetica",
                                          width: "150px",
                                        }}
                                        onClick={(e) => {
                                          this.CallRazorPay();
                                        }}
                                      >
                                        Pay
                                      </Button>
                                    </div>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            ) : (
                              <></>
                            )}
                          </Col>
                        </Row>
                        <br />
                        <div className="text-start ">
                          <span
                            style={{
                              fontSize: "18px",
                              // letterSpacing: "3px",
                              fontFamily: "Raleway bold",
                            }}
                          >
                            Quotation Details:
                          </span>
                        </div>
                        <div className="p-0 bg-light CornersRounded">
                          <Table
                            className="text-center w-100"
                            style={{
                              fontSize: "14px",
                              // letterSpacing: "3px",
                              fontFamily: "Raleway",
                            }}
                          >
                            <thead>
                              <th width="10%"></th>
                              <th width="80%">Requirement</th>
                              <th width="10%">Quote</th>
                            </thead>
                            <tbody>
                              {order.quoteItems.map((item, i) => (
                                <>
                                  <tr>
                                    <td width="10%" className="border">
                                      {" "}
                                      {i + 1}
                                    </td>
                                    <td
                                      width="80%"
                                      className="text-start border"
                                    >
                                      <b>Category Name:</b>
                                      <br />
                                      {item.categoryName}
                                      <br />
                                      <b>Product Name:</b>
                                      <br />
                                      {item.productName}
                                      <br />
                                      <b>Quantity:</b>
                                      {item.qty}
                                      <br />
                                      <b>Detailed Requirements:</b>
                                      <br />
                                      {item.detailedRequirement}
                                    </td>
                                    <td width="10%" className="text-end border">
                                      <h5>
                                        <b>
                                          {Number(
                                            item.quoteAmount
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </b>
                                      </h5>
                                    </td>
                                  </tr>
                                </>
                              ))}
                              <tr className="bg-white">
                                <td colSpan={2} className="text-end">
                                  <h5>
                                    Tax({this.context.taxDetails.taxPercentage}
                                    %):
                                  </h5>
                                </td>
                                <td className="text-end">
                                  <h5>
                                    {(
                                      (order.quoteItems.reduce(
                                        (a, v) =>
                                          (a = a + Number(v.quoteAmount)),
                                        0
                                      ) *
                                        this.context.taxDetails.taxPercentage) /
                                      100
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </h5>
                                </td>
                              </tr>
                              <tr className="bg-white">
                                <td colSpan={2} className="text-end">
                                  <h3>Total:</h3>
                                </td>
                                <td className="text-end">
                                  <h3>
                                    {(
                                      Number(
                                        order.quoteItems.reduce(
                                          (a, v) =>
                                            (a = a + Number(v.quoteAmount)),
                                          0
                                        )
                                      ) +
                                      Number(
                                        (order.quoteItems.reduce(
                                          (a, v) =>
                                            (a = a + Number(v.quoteAmount)),
                                          0
                                        ) *
                                          this.context.taxDetails
                                            .taxPercentage) /
                                          100
                                      )
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </h3>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                        {order.quotePayments &&
                        order.quotePayments.length > 0 ? (
                          <>
                            <br />
                            <Row>
                              <Col>
                                <span
                                  style={{
                                    fontSize: "18px",
                                    // letterSpacing: "3px",
                                    fontFamily: "Raleway bold",
                                  }}
                                >
                                  Payment History:
                                </span>
                                <Table
                                  className="bg-light CornersRounded"
                                  style={{
                                    fontSize: "14px",
                                    // letterSpacing: "3px",
                                    fontFamily: "Raleway",
                                  }}
                                >
                                  <thead>
                                    <th>Received</th>
                                    <th>TransactionID</th>
                                    <th>Amount</th>
                                  </thead>
                                  <tbody>
                                    {order.quotePayments.map((payment) => (
                                      <tr>
                                        <td>
                                          {this.context.GetInDateFormat(
                                            new Date(payment.created)
                                          )}
                                        </td>
                                        <td>{payment.paymentTransactionId}</td>
                                        <td>
                                          {Number(
                                            payment.amount
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 0,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </td>
                                      </tr>
                                    ))}
                                    <tr>
                                      <td></td>
                                      <td>
                                        <b>Total Paid:</b>
                                      </td>
                                      <td>
                                        <b>
                                          {order.quotePayments
                                            .reduce(
                                              (a, v) =>
                                                (a = a + Number(v.amount)),
                                              0
                                            )
                                            .toLocaleString(
                                              this.context.storeSettings
                                                .defaultLocale,
                                              {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0,
                                                style: "currency",
                                                currency:
                                                  this.context.storeSettings
                                                    .defaultCurrency,
                                              }
                                            )}
                                        </b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Col>
                            </Row>
                          </>
                        ) : (
                          <></>
                        )}
                        <span
                          style={{
                            fontSize: "24px",
                            // letterSpacing: "3px",
                            fontFamily: "Raleway bold",
                          }}
                        >
                          Delivery Address{" "}
                        </span>
                        <Row className="mx-0 my-2 bg-light p-3 CornersRounded">
                          <Col>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Name *"
                              className="mb-3  "
                            >
                              <Form.Control
                                type="text"
                                name="customerName"
                                placeholder="First Name *"
                                className="bg-white"
                                required
                                value={this.state.customerName}
                                onChange={(e) => {
                                  this.setState({
                                    customerName: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a name
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Mobile Number*"
                              className="mb-3  "
                            >
                              <Form.Control
                                type="text"
                                name="contactNumber"
                                placeholder="Mobile No *"
                                className="bg-white"
                                readOnly
                                value={order.contactNumber}
                                onChange={(e) => {
                                  this.setState({
                                    contactNumber: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid{" "}
                                {this.context.storeSettings.phoneNumberLength}
                                digit Phone Number!
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Email ID *"
                              className="mb-3  "
                            >
                              <Form.Control
                                type="email"
                                name="emailId"
                                placeholder="Email address *"
                                required
                                className="bg-white"
                                value={this.state.emailId}
                                onChange={(e) => {
                                  this.setState({
                                    emailId: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter a valid Email ID.
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <Form.Control
                              as="textarea"
                              name="address"
                              rows={2}
                              style={{ height: "120px" }}
                              className="mb-3  "
                              required
                              placeholder="Enter Address *"
                              value={this.state.address}
                              onChange={(e) => {
                                this.setState({
                                  address: e.target.value,
                                });
                              }}
                            />
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Country *"
                              className="mb-3  "
                            >
                              <Form.Select
                                value={this.state.country}
                                name="country"
                                required
                                onChange={(e) => {
                                  this.setState({
                                    country: e.target.value,
                                  });
                                }}
                              >
                                <option value=""></option>
                                {Country.getAllCountries().map((country) => (
                                  <>
                                    <option value={country.isoCode}>
                                      {country.name}
                                    </option>
                                  </>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select country
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingInput"
                              label={
                                window.location.hostname ==
                                "shop.green.com.pg" ? (
                                  <>Province *</>
                                ) : (
                                  <>State *</>
                                )
                              }
                              className="mb-3  "
                            >
                              <Form.Select
                                value={this.state.state}
                                name="state"
                                required
                                onChange={(e) => {
                                  this.setState({
                                    state: e.target.value,
                                  });
                                }}
                              >
                                <option value=""></option>
                                {State.getStatesOfCountry(
                                  this.state.country
                                ).map((s1) => (
                                  <>
                                    <option>{s1.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                              <Form.Control.Feedback type="invalid">
                                Please select State
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="City *"
                              className="mb-3  "
                            >
                              <Form.Control
                                type="text"
                                name="city"
                                placeholder="City *"
                                required
                                className="bg-white"
                                value={this.state.city}
                                onChange={(e) => {
                                  this.setState({
                                    city: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please Enter City
                              </Form.Control.Feedback>
                            </FloatingLabel>
                            <FloatingLabel
                              controlId="floatingInput"
                              label="Postal Code *"
                              className="mb-3  "
                            >
                              <Form.Control
                                type="number"
                                name="pincode"
                                placeholder="Postal Code *"
                                className="bg-white"
                                required
                                value={this.state.pincode}
                                onChange={(e) => {
                                  this.setState({
                                    pincode: e.target.value,
                                  });
                                }}
                              />
                              <Form.Control.Feedback type="invalid">
                                Please enter valid{" "}
                                {this.context.storeSettings.pincodeLength} digit
                                Postal Code!
                              </Form.Control.Feedback>
                            </FloatingLabel>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="text-center">
                            <Button
                              className=" "
                              style={{
                                backgroundColor: "#00ff00",
                                color: "white",
                                fontSize: "14px",
                                fontFamily: "Helvetica",
                                width: "250px",
                              }}
                              onClick={(e) =>
                                this.SaveDeliveryAddress(e, order)
                              }
                            >
                              Save Delivery Address
                            </Button>
                          </Col>
                        </Row>
                      </>
                    ))}
                </Form>
              </div>
            </div>
          </>
        )}

        <div style={{ paddingBottom: "500px" }}></div>
      </>
    );
  }
}
